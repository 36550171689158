/* eslint-disable react/jsx-props-no-spreading */
import Video from '../video/Video'
import { getImageUrl } from '../../utils'

function getStyle(item, width) {
  const url = `${getImageUrl(item, width)}`
  if (!url) return {}
  if (!item || item.type === 'video') return {}
  return {
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${url})`,
  }
}

function MediaItem({ data, cover, className, width }) {
  const isVideo = data && data.type === 'video'
  const cn = className || 'column'
  return (
    <div
      style={getStyle(data, width)}
      {...(cover ? { id: 'cover' } : { className: cn })}
    >
      {isVideo && (
        <Video data={data} style={{ maxWidth: '100%', maxHeight: '100%' }} />
      )}
    </div>
  )
}

export default MediaItem
