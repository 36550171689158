import React, { setGlobal } from 'reactn'
import { hydrate, render } from 'react-dom'
import './index.css'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

setGlobal({
  messages: {},
  conversations: [],
  matches: {
    unanswered: {
      matches: [],
      nextToken: 0,
      scroll: 0
    }
  },
  matchTab: null,
  user: null,
  listing: null,
  isDesktop: false,
  isMobile: false
})

function Wrapper() {
  return (
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  )
}

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(<Wrapper />, rootElement)
} else {
  render(<Wrapper />, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
