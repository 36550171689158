function MiscFlatData({ data }) {
  if (!data) return <></>
  const { constructionYear, landlord } = data
  return (
    <div id="misc-flat-data">
      <h2>Övrigt</h2>
      <div className="flat-data-item">
        <div>Byggår</div>
        <div>{constructionYear}</div>
      </div>
      <div className="flat-data-item">
        <div>Hyresvärd</div>
        <div>{landlord}</div>
      </div>
    </div>
  )
}

export default MiscFlatData
