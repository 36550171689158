/* eslint-disable operator-linebreak */
import React, { useState } from 'react'

const hiddenCheckboxStyle = {
  border: '0',
  clip: 'rect(0 0 0 0)',
  clippath: 'inset(50%)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}
const checkboxContainerStyle = {
  display: 'inline-block',
  verticalAlign: 'middle',
}
const iconStyle = {
  fill: 'none',
  strokeWidth: '2px',
}
const styledCheckboxStyle = {
  display: 'inline-block',
  width: '16px',
  height: '16px',
  borderRadius: '3px',
  transition: 'all 150ms',
}

function Checkbox({
  label,
  value,
  className,
  onChange,
  children,
  labelFirst = false,
  focusBorderColor = 'pink',
  borderColor = '#E95D00',
  backgroundWhenChecked = '#E95D00',
  backgroundWhenNotChecked = 'white',
}) {
  const [focused, setFocused] = useState(false)

  return (
    <label>
      {labelFirst && <span style={{ marginRight: 8 }}>{label || ''}</span>}
      <div // CheckboxContainer
        style={checkboxContainerStyle}
        className={className}
      >
        <input // HiddenCheckbox
          type="checkbox"
          checked={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => onChange(e.target.checked)}
          style={hiddenCheckboxStyle}
        />
        <div
          checked={value}
          style={{
            ...styledCheckboxStyle,
            boxShadow: focused
              ? `0 0 0 3px ${focusBorderColor}`
              : `0 0 0 1px ${borderColor}`,
            background: value ? backgroundWhenChecked : backgroundWhenNotChecked,
          }}
        >
          <svg
            viewBox="0 0 24 24"
            style={{
              ...iconStyle,
              stroke: 'white',
              paddingBottom: 1,
              visibility: value ? 'visible' : 'hidden',
            }}
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
        </div>
      </div>
      {!labelFirst && <span style={{ marginLeft: 8 }}>{label || ''}</span>}
      {children}
    </label>
  )
}

export default Checkbox
