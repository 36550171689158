import styled from 'styled-components'

const StyleTag = styled.div`
  padding: 5px 7px 3px 7px;
  background: #333;
  color: white;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 700;
  border-radius: 3px;
  display: inline;
`

function Tag({ children, style }) {
  return <StyleTag style={style}>{children}</StyleTag>
}

export default Tag
