import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  padding: 15px 19px;
  color: white;
  border: 1px solid #aaa;
  border-radius: 7px;
  box-sizing: border-box;
  background: white;
  box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.05);
  color: black;
  font-weight: 400;
  width: 100%;
  &:disabled {
    background: lightgrey;
  }
`

function Input(
  { type, value, onChange, onBlur, onFocus, onKeyDown, onEnter, placeholder, style, disabled },
  ref
) {
  function onKey(e) {
    if (onEnter && (e.code === 'Enter' || e.code === 'NumpadEnter')) {
      onEnter()
    } else if (onKeyDown) onKeyDown(e)
  }

  return (
    <StyledInput
      ref={ref}
      disabled={disabled}
      value={value || ''}
      onKeyDown={onKey}
      onBlur={onBlur}
      onFocus={onFocus}
      placeholder={placeholder}
      style={style}
      onChange={onChange || (() => console.log('missing onchange'))}
      type={type || 'text'}
    />
  )
}

export default React.forwardRef(Input)
