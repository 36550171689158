import styled from 'styled-components'

const StyledBox = styled.div`
  padding: 15px;
  border-radius: 7px;
  box-sizing: border-box;
  width: 100%;
  background: var(--formColor);
  border: none;
  text-align: left;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 0px 0px;
  @media only screen and (min-width: 768px) {
    padding: 25px;
  }
  h2 {
    margin-top: 0;
    font-size: 0.8rem;
  }
`

function Box({ children, style }) {
  return <StyledBox style={style}>{children}</StyledBox>
}

export default Box
