/* eslint-disable react/jsx-props-no-spreading */
import { useMediaQuery } from 'react-responsive'
import { mobileMaxSize } from '../../settings'

const Mobile = ({ children, style, className, fullscreen }) => {
  const isMobile = useMediaQuery({ maxWidth: mobileMaxSize })

  if (!isMobile) return null

  if (!style && !className && !fullscreen) return children
  return (
    <div {...(style && { style })} {...(className && { className })}>
      {children}
    </div>
  )
}

export default Mobile
