import { Checkbox } from '../../../components'
import { featuresTexts } from '../../../settings'

function Features({ data, setData, options = featuresTexts }) {
  // handle both editflat & want
  const includedFeatures = data.features || data.desiredFeatures || []

  function toggle(feature, value) {
    const features = JSON.parse(JSON.stringify(includedFeatures))
    const i = features.indexOf(feature)
    if (i > -1) features.splice(i, 1)
    if (value) features.push(feature)
    setData({ ...data, features })
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'inline-block', margin: 'auto', textAlign: 'left' }}>
        {Object.entries(options).map(([key, text]) => (
          <div key={key} style={{ marginBottom: 10 }}>
            <Checkbox
              value={includedFeatures.includes(key)}
              label={text || 'text saknas'}
              onChange={(e) => toggle(key, e)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Features
