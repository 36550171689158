/* eslint-disable no-unused-expressions */
import { Link } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import { useGlobal, useDispatch } from 'reactn'
import { API, graphqlOperation } from 'aws-amplify'
import { Error, Icon, Input, Loading, Mobile } from '../../components'
import { getMessages } from '../../graphql/queries'
import { getConversation, sendMessage, sendReadConfirmation } from '../../graphql/mutations'
import { subscribeToSendMessage } from '../../graphql/subscriptions'

function Empty({ className, style, loading }) {
  return (
    <div id="conversation" className={className} style={style}>
      <div style={{ background: '#eee', width: '100%', height: '100vh' }}>{loading && <Loading />}</div>
    </div>
  )
}

function Message({ data }) {
  const [listing] = useGlobal('listing')
  let cn = 'messageWrapper'
  let sender = ''
  if (data.sender === listing.id) {
    cn += ' mine'
    sender = 'du'
  }
  return (
    <div className={cn}>
      <div className="sender">{sender}</div>
      <div className="message">{data.message}</div>
    </div>
  )
}

function Messages({ conversationId, updated }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [messages, setMessages] = useGlobal('messages')

  async function loadData() {
    try {
      const res = await API.graphql(graphqlOperation(getMessages, { conversationId }))
      const items = JSON.parse(JSON.stringify(res.data.getMessages.messages))
      setMessages({ ...messages, [conversationId]: items.reverse() })
      setLoading(false)
    } catch (e) {
      setError(e)
    }
  }
  useEffect(loadData, [conversationId, updated])

  useEffect(() => {
    // scroll to last message
    const elem = document.getElementById('messages')
    if (elem) elem.scrollTo(0, elem.scrollHeight)
  }, [messages])

  if (loading) return <Loading />
  if (error) return <Error data={error} />
  const items = messages[conversationId] || []

  return (
    <>
      <div id="messages">
        {items.map((m) => (
          <Message key={m.messageId} data={m} />
        ))}
      </div>
    </>
  )
}

const addMessageReducer = (messages, { conversationId, message, sender, timestamp, messageId }) => ({
  ...messages,
  [conversationId]: [...messages[conversationId], { message, sender, timestamp, messageId }]
})

function SendMessage({ conversationId }) {
  const [listing] = useGlobal('listing')
  const [message, setMessage] = useState('')
  const addMessage = useDispatch(addMessageReducer, 'messages')
  const textInputRef = useRef(null)

  const sender = listing.id

  async function send() {
    addMessage({ message, sender, timestamp: Date.now(), messageId: Date.now(), conversationId })
    await API.graphql(graphqlOperation(sendMessage, { message, sender, conversationId }))
  }

  function onSend() {
    send()
    setMessage('')
  }

  useEffect(() => {
    textInputRef.current.focus()
  }, [conversationId])

  return (
    <div id="conversationFooter">
      <Input
        ref={textInputRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onEnter={onSend}
        style={{ marginBottom: 10, marginTop: 10 }}
        placeholder="Aa"
      />
    </div>
  )
}

const addConversationReducer = (conversations, conversation) => [{ ...conversation }, ...conversations]

function Conversation({ conversationId, className, style }) {
  if (!conversationId) {
    return <Empty className={className} style={style} />
  }
  const [listing] = useGlobal('listing')
  const listingId = listing.id
  const [conversation, setConversation] = useState(null)
  const [conversations, setConversations] = useGlobal('conversations')
  const addConversation = useDispatch(addConversationReducer, 'conversations')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [updated, setUpdated] = useState(new Date().getTime())
  const addMessage = useDispatch(addMessageReducer, 'messages')

  useEffect(() => {
    // new conversations are not part of getConverversations response. Add them to the list
    if (conversation && conversations.every((x) => x.conversationId !== conversationId)) {
      const { part1Street } = conversation
      addConversation({ conversationId, part1Street, lastMessage: { message: 'Nytt meddelande', sender: listingId } })
    }
  }, [conversations, conversation])

  let subscription

  async function loadData() {
    try {
      const cache = conversations.find((x) => x.conversationId === conversationId)
      if (cache) {
        setConversation(cache)
      }
      const res = await API.graphql(graphqlOperation(getConversation, { conversationId, listingId }))

      const conv = res.data.getConversation
      setConversation(conv)
      setLoading(false)

      await API.graphql(graphqlOperation(sendReadConfirmation, { conversationId, listingId }))

      // if conversation was unread update global state to update conversation list and unread counter
      const { lastRead, lastMessage } = conv
      if (lastRead < (lastMessage?.timestamp || 0)) {
        const convs = conversations.map((x) => ({
          ...x,
          lastRead: x.conversationId === conversationId ? Date.now() : x.lastRead
        }))
        setConversations(convs)
      }
    } catch (e) {
      setError(e)
    }
  }

  function subscribe() {
    try {
      subscription = API.graphql(graphqlOperation(subscribeToSendMessage, { conversationId })).subscribe({
        // next: ({ provider, value }) => setUpdated(new Date().getTime()),
        next: ({ value }) => {
          const message = value.data.subscribeToSendMessage
          if (message.sender !== listingId) {
            addMessage(message)
          }

          setUpdated(new Date().getTime())
          API.graphql(graphqlOperation(sendReadConfirmation, { conversationId, listingId }))
        },
        error: (err) => console.warn(err)
      })
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    loadData()
    subscribe()
    return () => {
      subscription && subscription.unsubscribe()
    }
  }, [conversationId])

  if (loading) return <Empty loading className={className} style={style} />
  if (error) return <Error data={error} />
  if (!conversation) return <Empty className={className} style={style} />

  const { part1Street, part2Street } = conversation
  let name = part1Street
  if (part2Street) name += ` & ${part2Street}`
  return (
    <div id="conversation" className={className} style={style}>
      <div id="conversationHeader">
        <h2>
          <Mobile>
            <Link to="/inbox">
              <Icon
                type="solid"
                style={{
                  color: 'var(--primaryColor)',
                  fontSize: 25,
                  marginRight: 15,
                  marginBottom: 2
                }}
                icon="chevron-left"
              />
            </Link>
          </Mobile>
          {name}
        </h2>
      </div>
      <Messages conversationId={conversationId} updated={updated} />
      <SendMessage conversationId={conversationId} refetch={() => console.log('REFETCH')} />
    </div>
  )
}

export default Conversation
