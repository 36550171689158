import styled from 'styled-components'
import Button from '../Button'

const LinkButton = styled(Button)`
    background:none;
    color:#D96629;
    padding-left:0;
    padding-right:0;
`

export default LinkButton
