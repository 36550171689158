import { useGlobal } from 'reactn'
import FadeIn from 'react-fade-in'
import { Button } from '../../components'
import { getCurrentAnswer } from '../../utils'

function NextButton() {
  return (
    <FadeIn delay="1000">
      <div id="matchControls">
        <Button
          onClick={() => window.location.reload()}
          style={{
            background: 'lightseagreen',
            color: 'white',
          }}
        >
          Nästa match
        </Button>
      </div>
    </FadeIn>
  )
}

function NextMatch() {
  const [listing] = useGlobal('listing')
  const [currentListing] = useGlobal('currentListing')
  const currentAnswer = getCurrentAnswer(listing, currentListing)
  const [isMatching] = useGlobal('isMatching')

  if (!isMatching) return <></>
  if (currentAnswer !== 'yes') return <></>

  return <NextButton />
}
export default NextMatch
