import { useLocation } from 'react-router-dom'

export function cloneDeep(o) {
  return JSON.parse(JSON.stringify(o))
}

export function getCoverImage(mediaItems) {
  return (mediaItems && mediaItems[0]) || { id: 'blog/placeholder.png' }
}

export function getImageUrl(item, width) {
  if (!item) return null
  if (item.id.includes('lagenhetsbyte')) {
    return item.id
  }
  if (width) return `https://res.cloudinary.com/lyamotlya/image/upload/w_${width}/v1/${item.id}.jpg`
  return `https://res.cloudinary.com/lyamotlya/image/upload/v1/${item.id}.jpg`
}

export function getConversationId(listings) {
  listings.sort()
  return listings.join('-')
}

export function getCurrentAnswer(listing, currentListing) {
  if (!currentListing || !currentListing.id) return null
  if (!listing) return null

  const id = currentListing.id
  const yes = cloneDeep(listing.yes || [])
  const no = cloneDeep(listing.no || [])

  let currentAnswer
  if (yes.indexOf(id) > -1) currentAnswer = 'yes'
  if (no.indexOf(id) > -1) currentAnswer = 'no'
  return currentAnswer
}
export function useQuery() {
  return new URLSearchParams(useLocation().search)
}
