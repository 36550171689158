import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

import { useGlobal } from 'reactn'
import { API, graphqlOperation } from 'aws-amplify'
import { getListing } from '../../graphql/queries'
import { Error, Loading, Video } from '../../components'
import { getImageUrl } from '../../utils'

function MediaItem({ data }) {
  const isVideo = data && data.type === 'video'
  const url = getImageUrl(data)

  console.log(data)
  return (
    <div style={{ marginBottom: 30 }}>
      {!isVideo && <img src={url} alt="Bild" />}
      {isVideo && <Video data={data} style={{ maxWidth: '100%', maxHeight: '100%' }} />}
    </div>
  )
}

function MediaOverview() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [currentListing, setCurrentListing] = useGlobal('currentListing')
  const { id } = useParams()

  async function loadData() {
    console.log('load data')
    try {
      if (currentListing) {
        setLoading(false)
        return true
      }
      const res = await API.graphql(graphqlOperation(getListing, { id }))
      console.log('res', res)
      setCurrentListing(res.data.getListing)
      setLoading(false)
    } catch (e) {
      setError(e)
    }
  }
  useEffect(loadData, [id])

  if (loading) return <Loading />
  if (error) return <Error data={error} />
  if (!currentListing) return <div id="match">Här var det tomt</div>
  const media = currentListing.flat1?.media || []
  console.log(media)
  return (
    <div className="content" style={{ marginTop: 30 }}>
      <p>
        <Link to={`/flat/${id}`}>&lt; Tillbaka</Link>
      </p>
      {media.map((item) => (
        <MediaItem key={item.id} data={item} />
      ))}
      <hr />
    </div>
  )
}

export default MediaOverview
