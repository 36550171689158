/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToSendMessage = /* GraphQL */ `
  subscription SubscribeToSendMessage($conversationId: ID!) {
    subscribeToSendMessage(conversationId: $conversationId) {
      conversationId
      messageId
      sender
      timestamp
      message
    }
  }
`;
