import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import { useGlobal } from 'reactn'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import Landing from './routes/landing/Landing'
import Blog from './routes/blog/Blog'
import Logo from './components/logo/Logo'
import {
  Overview,
  EditFlat,
  Flat,
  MediaOverview,
  Misc,
  About,
  Help,
  Feedback,
  Terms,
  Inbox,
  Match,
  Matches,
  Want,
  NotFound404,
  Signup,
  ConfirmSignup,
  Login,
  Signout,
  NewUser
} from './routes'
import LoggedIn from './LoggedIn'
import { amplifyConfCognito, amplifyConfApiKey } from './settings'

Amplify.configure(amplifyConfCognito)

function App() {
  const [user] = useGlobal('user')
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    console.log('Init analytics')
    ReactGA.initialize('G-SDJ6PSL73F')
  }, [])

  useEffect(async () => {
    try {
      Amplify.configure(amplifyConfCognito)
      await Auth.currentAuthenticatedUser()
      if (location.pathname === '/') {
        history.push('/overview')
      }
    } catch (e) {
      console.error(e)
      Amplify.configure(amplifyConfApiKey)
    }
  }, [user])

  return (
    <Switch>
      {/* Public routes */}
      <Route path="/" exact>
        <Logo alone />
        <Landing />
      </Route>
      <Route path="/blog/:blogId">
        <Logo alone />
        <Blog />
      </Route>
      <Route path="/start">
        <Logo alone />
        <Landing />
      </Route>
      <Route path="/login">
        <Logo alone />
        <Login />
      </Route>
      <Route path="/signup">
        <Logo alone />
        <Signup />
      </Route>
      <Route path="/signout">
        <Signout />
      </Route>
      <Route path="/confirmsignup">
        <Logo alone />
        <ConfirmSignup />
      </Route>

      {/* Private routes */}
      <Route path="/overview">
        <LoggedIn showLogo showMenu>
          <Overview />
        </LoggedIn>
      </Route>
      <Route path="/matches">
        <LoggedIn showLogo showMenu>
          <Matches />
        </LoggedIn>
      </Route>
      <Route path="/match">
        <LoggedIn>
          <Match />
        </LoggedIn>
      </Route>
      <Route exact path="/flat/:id">
        <LoggedIn>
          <Flat />
        </LoggedIn>
      </Route>
      <Route exact path="/flat/:id/media">
        <LoggedIn>
          <MediaOverview />
        </LoggedIn>
      </Route>
      <Route exact path="/want/:listingField">
        <LoggedIn>
          <Want />
        </LoggedIn>
      </Route>
      <Route exact path="/inbox/:conversationId">
        <LoggedIn showLogo>
          <Inbox />
        </LoggedIn>
      </Route>
      <Route path="/inbox">
        <LoggedIn showLogo showMenu>
          <Inbox />
        </LoggedIn>
      </Route>

      <Route exact path="/misc">
        <LoggedIn showLogo showMenu>
          <Misc />
        </LoggedIn>
      </Route>
      <Route exact path="/misc/help">
        <LoggedIn showLogo showMenu>
          <Help />
        </LoggedIn>
      </Route>
      <Route path="/misc/feedback">
        <LoggedIn showLogo showMenu>
          <Feedback />
        </LoggedIn>
      </Route>
      <Route path="/misc/terms">
        <LoggedIn showLogo showMenu>
          <Terms />
        </LoggedIn>
      </Route>
      <Route path="/misc/about">
        <LoggedIn showLogo showMenu>
          <About />
        </LoggedIn>
      </Route>
      <Route exact path="/flat/edit/:listingField">
        <LoggedIn>
          <EditFlat />
        </LoggedIn>
      </Route>
      <Route path="/newuser">
        <LoggedIn showLogo showMenu>
          <NewUser />
        </LoggedIn>
      </Route>
      <Route>
        <Logo alone />
        <NotFound404 />
      </Route>
    </Switch>
  )
}

export default App
