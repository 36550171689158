import React from 'react'
import styled from 'styled-components'
import Input from '../Input'

const Helper = styled.div`
  font-weight: normal;
  color: tomato;
`

const Wrapper = styled.div`
  div {
    font-weight: 700;
    margin-bottom: 5px;
  }
  margin-bottom: 15px;
`

const LabelInput = (
  { label, type, value, onChange, onBlur, onFocus, onKeyDown, onEnter, placeholder, helper, disabled },
  ref
) => (
  <Wrapper>
    <label>
      <div>{label}</div>
      <Input
        disabled={disabled}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onEnter={onEnter}
        type={type}
        ref={ref}
      />
    </label>
    <Helper>{helper}</Helper>
  </Wrapper>
)

export default React.forwardRef(LabelInput)
