import { useEffect, useState } from 'react'
import { useGlobal } from 'reactn'
import { API, graphqlOperation } from 'aws-amplify'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getMatches, getCompleteMatches } from '../../graphql/queries'
import { ToggleButtonGroup, Loading, Error, Icon } from '../../components'
import MatchListItem from './MatchListItem'
import './Matches.css'

const ScrollLoader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', height: 200, marginTop: 30 }}>
    <Icon className="icon" type="duotone" style={{ fontSize: 100, color: '#E95D00' }} spin icon="circle-notch" />
  </div>
)

function MatchesList({ filter }) {
  const [data, setData] = useGlobal('matches')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [listing] = useGlobal('listing')
  const [currentFilter, setCurrentFilter] = useState(filter)
  const query = filter === 'matches' ? getCompleteMatches : getMatches

  async function loadData() {
    const { nextToken = 0, scroll } = data[filter] || {}
    try {
      const variables = {
        id: listing.id,
        filter,
        limit: parseInt(nextToken, 10) + 20,
        nextToken: 0
      }
      const res = await API.graphql(graphqlOperation(query, variables))
      const result = res.data.getMatches || {}
      setLoading(false)

      setData({
        ...data,
        [filter]: {
          ...result,
          scroll
        }
      })
    } catch (e) {
      setError(e)
    }
  }
  useEffect(() => {
    loadData()
    // when switching tabs maintain scroll position
    if (filter !== currentFilter) {
      const elem = document.getElementById('matchList')
      if (elem) {
        elem.scrollTo(0, data[filter]?.scroll || 0)
      }
      setLoading(true)
    }
    setCurrentFilter(filter)
  }, [filter])

  function updateScroll() {
    setData({
      ...data,
      [filter]: {
        ...data[filter],
        scroll: document.getElementById('matchList').scrollTop
      }
    })
  }

  const items = data[filter]?.matches || []

  if (items.length === 0 && loading) return <Loading />
  if (error) return <Error data={error} />
  if (items.length === 0) {
    return <p style={{ textAlign: 'center' }}>Här var det tomt!</p>
  }

  return (
    <div id="matchList">
      <InfiniteScroll
        dataLength={items.length}
        next={() => loadData()}
        style={{ display: 'flex', flexDirection: 'column' }}
        hasMore={data[filter]?.nextToken > 0}
        loader={<ScrollLoader />}
        initialScrollY={data[filter]?.scroll || 0}
        onScroll={updateScroll}
        scrollableTarget="matchList"
      >
        {items.map((item) => (
          <MatchListItem key={item.listing.id} data={item} />
        ))}
      </InfiniteScroll>
    </div>
  )
}

function Matches() {
  const [tab, setTab] = useGlobal('matchTab')

  const tabItems = [
    { label: 'Obesvarade', value: 'unanswered' },
    { label: 'Matchningar', value: 'matches' },
    { label: 'Ja', value: 'yes' },
    { label: 'Nej', value: 'no' }
  ]

  const t = tab || 'unanswered'
  return (
    <>
      <ToggleButtonGroup
        items={tabItems}
        value={t}
        onClick={setTab}
        style={{ maxWidth: 400, width: '90%', marginTop: 20, marginBottom: 20, height: 30 }}
      />
      <MatchesList filter={t} />
    </>
  )
}

export default Matches
