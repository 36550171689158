import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCoffee as duoToneCoffee,
  faCircleNotch,
  faMapPin,
  faTrash,
  faTimesCircle,
  faUnicorn
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faCoffee as solidCoffee,
  faChevronLeft,
  faCheckSquare,
  faTimesSquare,
  faQuestionSquare,
  faChevronRight,
  faLongArrowRight,
  faMapMarkerAlt
} from '@fortawesome/pro-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faListUl,
  faExchange,
  faLock,
  faEnvelope,
  faEllipsisHAlt,
  faSortCircleUp,
  faSunCloud,
  faFireplace,
  faWasher,
  faWindowAlt,
  faBath,
  faDoorClosed,
  faFileSignature,
  faWarehouse,
  faCar,
  faTimes
} from '@fortawesome/pro-light-svg-icons'

library.add(
  duoToneCoffee,
  solidCoffee,
  faCircleNotch,
  faUser,
  faListUl,
  faExchange,
  faEnvelope,
  faEllipsisHAlt,
  faMapPin,
  faSortCircleUp,
  faSunCloud,
  faFireplace,
  faWasher,
  faWindowAlt,
  faBath,
  faDoorClosed,
  faFileSignature,
  faWarehouse,
  faCar,
  faTrash,
  faTimesCircle,
  faChevronLeft,
  faChevronRight,
  faLock,
  faCheckSquare,
  faUnicorn,
  faTimesSquare,
  faQuestionSquare,
  faLongArrowRight,
  faMapMarkerAlt,
  faTimes
)

function Icon({ icon, type, spin, className, style, onClick }) {
  let lib = 'fas'
  if (type === 'duotone') lib = 'fad'
  if (type === 'light') lib = 'fal'

  return (
    <FontAwesomeIcon
      spin={spin}
      style={style || {}}
      className={className || ''}
      icon={[lib, icon || 'coffee']}
      onClick={onClick}
    />
  )
}

export default Icon
