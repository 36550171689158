import img from '../../assets/logo.svg'

function Logo({ alone }) {
  let className
  if (alone) className = 'landing'
  return (
    <a id="logo" className={className || ''} href="/">
      <img src={img} alt="logo" />
    </a>
  )
}
export default Logo
