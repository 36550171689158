/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
function getCurrentPosition() {
  const options = { enableHighAccuracy: true }
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  )
}

const geocodeByLatLng = (latLng) => {
  const geocoder = new window.google.maps.Geocoder()
  const { OK } = window.google.maps.GeocoderStatus

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status !== OK) return reject(status)

      return resolve(results)
    })
  })
}

async function getCurrentAddress() {
  const pos = await getCurrentPosition()
  const latLng = new window.google.maps.LatLng(
    pos.coords.latitude,
    pos.coords.longitude
  )
  const result = await geocodeByLatLng(latLng)
  const address = result.filter((x) => x.types.includes('street_address'))[0]
  return address
}

async function getPlaceDetails(placeId) {
  const placesService = new window.google.maps.places.PlacesService(
    document.createElement('div')
  )
  const { OK } = window.google.maps.places.PlacesServiceStatus

  return new Promise((resolve, reject) => {
    placesService.getDetails({
      placeId,
      fields: ['address_components', 'formatted_address', 'name', 'geometry', 'types']
    }, (result, status) => {
      console.log({ getPlaceDetails: result, status: status === OK })
      console.log(result.geometry.location.toJSON())
      if (status !== OK) return reject(status)
      return resolve(result)
    })
  })
}

export {
  geocodeByLatLng,
  getCurrentPosition,
  getCurrentAddress,
  getPlaceDetails,
}
