/* eslint-disable no-underscore-dangle */
import { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import '../flat/editflat/EditFlat.css'
import './Want.css'
import FadeIn from 'react-fade-in'
import { useGlobal } from 'reactn'

import { API, graphqlOperation } from 'aws-amplify'
import { LabelInput, Button, LinkButton } from '../../components'
import texts from './texts.json'
import Features from '../flat/editflat/Features'
import { saveListing } from '../../graphql/mutations'
import Areas from './Areas'
import { featuresWantTexts } from '../../settings'

function Want() {
  const { listingField } = useParams()
  const [step, setStep] = useState(1)
  const [listing, setListing] = useGlobal('listing')
  console.log(listing)
  console.log(listingField)
  const wantItem = listing[listingField] && listing[listingField][0]
  const [data, setData] = useState(wantItem || {})
  const history = useHistory()

  async function save() {
    console.log('SAVE', listingField)
    const saveData = JSON.parse(JSON.stringify(listing))
    saveData[listingField] = [data]
    const d = await API.graphql(graphqlOperation(saveListing, saveData))
    console.log('Save OK', d)
    setListing(saveData)
  }

  function updateField(field, value) {
    setData({ ...data, [field]: value })
  }

  function done() {
    save()
    history.push('/overview')
  }

  function input(label, field, type, onBlur) {
    return (
      <LabelInput
        label={label}
        value={data[field]}
        onBlur={onBlur}
        type={type || 'text'}
        onChange={(e) => updateField(field, e.target.value)}
      />
    )
  }

  const title = <h1>{texts[`step-${step}-title`]}</h1>
  const description = (
    <div
      id="stepDescription"
      dangerouslySetInnerHTML={{
        __html: texts[`step-${step}-description`],
      }}
    />
  )
  const helper = <p>{texts[`step-${step}-helper`]}</p>

  const stepCount = 5
  return (
    <div className="content editFlat">
      <div id="editFlatHeader">
        <div style={{ marginBottom: 5 }}>
          Steg {step} av {stepCount}
        </div>
        <LinkButton
          style={{ float: 'right', paddingTop: 0 }}
          onClick={() => done()}
        >
          Avsluta och spara
        </LinkButton>
      </div>
      <hr style={{ width: '100%' }} />
      <p
        style={{
          fontSize: 10,
          color: 'pink',
          position: 'fixed',
          top: 0,
          right: 15,
        }}
      />
      {step === 1 && (
        <>
          {title}
          {description}
          <FadeIn>{input(null, 'minRoomCount', 'number')}</FadeIn>
        </>
      )}
      {step === 2 && (
        <>
          {title}
          {description}
          <FadeIn>{input(null, 'minArea', 'number')}</FadeIn>
        </>
      )}
      {step === 3 && (
        <>
          {title}
          {description}
          <FadeIn>{input(null, 'maxRent', 'number')}</FadeIn>
        </>
      )}
      {step === 4 && (
      <>
        <>
          {title}
          {description}
        </>
        <FadeIn><Areas data={data} setData={setData} /></FadeIn>
        {helper}
      </>
      )}
      {step === 5 && (
        <>
          <>
            {title}
            {description}
          </>
          <Features data={data} setData={setData} save={save} options={featuresWantTexts} />
        </>
      )}
      <div id="controls" style={{ marginTop: 50 }}>
        {step === 1 && <div />}
        {step > 1 && (
          <LinkButton onClick={() => setStep(step - 1)}>
            &lt; Tillbaka
          </LinkButton>
        )}
        {step < stepCount && (
          <Button
            onClick={() => {
              setStep(step + 1)
              save()
            }}
          >
            Nästa
          </Button>
        )}
        {step === stepCount && <Button onClick={done}>Klart!</Button>}
      </div>
    </div>
  )
}

export default Want
