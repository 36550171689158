import React, { useState } from 'react'
import styled from 'styled-components'
import { getCurrentAddress, getPlaceDetails } from './utils'
import Icon from '../../../../components/icon/Icon'
import Button from '../../../../components/buttons'

const GeoButton = styled(Button)`
  width: 100%;
  border-radius: 10px;
  background: white;
  border: 1px solid #d96629;
  color: #d96629;
  svg {
    margin-right: 5px;
    margin-top: -5px;
    vertical-align: middle;
  }
`

const Helper = styled.div`
  color: tomato;
  margin-bottom: 15px;
`

const GeoLocationButton = ({ updateDataFromPlace }) => {
  const [loading, setLoading] = useState(false)
  const [helper, setHelper] = useState()

  async function getAddress() {
    if (loading) {
      return
    }
    setLoading(true)
    setHelper(null)

    try {
      const result = await getCurrentAddress()
      const place = await getPlaceDetails(result.place_id)
      updateDataFromPlace(place)
    } catch (e) {
      console.log(e)
      setHelper('Kunde inte hämta plats information. Skriv in address nedan.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <GeoButton type="button" onClick={() => getAddress()}>
        {!loading && (
        <div><Icon
          className="icon"
          style={{ fontSize: 20 }}
          icon="map-marker-alt"
        />Hämta min postion
        </div>
        )}
        {loading && (
        <Icon
          className="icon"
          type="duotone"
          style={{ fontSize: 20, color: '#E95D00' }}
          spin
          icon="circle-notch"
        />
        )}
      </GeoButton>
      <Helper>{helper}</Helper>
    </div>
  )
}

export default GeoLocationButton
