import { GetAreaNames } from '../../components/areaName/AreaName'

function WantItems({ items, title }) {
  if (!items || items.length < 1) return <></>
  const { minArea, maxRent, areas } = items[0]
  return (
    <div>
      {title && <b>{title}</b>}
      <ul>
        <li>Minst {minArea} kvm</li>
        <li>Högst {maxRent} kr/mån</li>
        <li>{GetAreaNames(areas)}</li>
      </ul>
    </div>
  )
}

function Want({ listing }) {
  const want1 = listing.want1
  const want2 = listing.want2

  if (!want1) {
    return (
      <div id="want">
        <h2>Önskemål</h2>
        <p>Inga önskemål angivna</p>
      </div>
    )
  }

  return (
    <div id="want">
      <h2>Önskemål</h2>
      {want2 && <p>Den här medlemmen söker 2 lägenheter</p>}
      <WantItems items={want1} title={want2 ? 'Lägenhet 1' : undefined} />
      <WantItems items={want2} title="Lägenhet 2" />
    </div>
  )
}

export default Want
