/* eslint-disable react/no-danger */
import texts from './texts.json'

function Footer() {
  return (
    <div id="footer">
      <div dangerouslySetInnerHTML={{ __html: texts.footer }} />
    </div>
  )
}

export default Footer
