import { useState } from 'react'
import styled from 'styled-components'
import { LabelInput } from '..'

const SuggestionsWrapper = styled.div`
  position: relative;
`

const SuggestionsList = styled.div`
  position: absolute;
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  z-index: 1;

  div {
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 5px;
    padding-top: 5px;
  }
`

const SuggestionItem = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding-left: 10px;
  background: ${(props) => (props.active ? '#E1EBFF' : '')};
  :hover {
    background: #e1ebdd;
  }
`

const AutoComplete = ({ data, setData, suggestions }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [input, setInput] = useState(data.landlord || '')

  const onChange = (e) => {
    const userInput = e.target.value

    const filtered = suggestions.filter((suggestion) => suggestion.toLowerCase().includes(userInput.toLowerCase()))

    setInput(userInput)
    setFilteredSuggestions(filtered)
    setActiveSuggestionIndex(0)
    setShowSuggestions(true)
  }

  const onSuggestionClick = (e) => {
    setFilteredSuggestions([])
    setInput(e.target.innerText)
    setShowSuggestions(false)
  }

  const onFocus = () => {
    if (input === '') {
      setFilteredSuggestions(suggestions)
    }
    setShowSuggestions(true)
  }

  const onBlur = () => {
    setShowSuggestions(false)
    setFilteredSuggestions([])
    if (input !== data.landlord) {
      setData({ ...data, landlord: input === '' ? null : input })
    }
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // enter key
      let landlord = input
      if (filteredSuggestions.length) {
        landlord = filteredSuggestions[activeSuggestionIndex]
        setInput(landlord)
      }
      setShowSuggestions(false)
      setFilteredSuggestions([])
      setData({ ...data, landlord })
    } else if (e.keyCode === 38) {
      // up arrow
      if (activeSuggestionIndex === 0) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1)
    } else if (e.keyCode === 40) {
      // down arrow
      if (activeSuggestionIndex === filteredSuggestions.length - 1) {
        return
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1)
    }
  }

  const SuggestionsListComponent = () => (
    <SuggestionsList active={activeSuggestionIndex}>
      <div>
        {filteredSuggestions.map((suggestion, index) => (
          <SuggestionItem
            onMouseDown={onSuggestionClick}
            active={activeSuggestionIndex === index}
            key={suggestion}
          >
            {suggestion}
          </SuggestionItem>
        ))}
      </div>
    </SuggestionsList>
  )

  return (
    <SuggestionsWrapper>
      <LabelInput
        onKeyDown={onKeyDown}
        label="Hyresvärd"
        value={input}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {showSuggestions && filteredSuggestions.length > 0 && <SuggestionsListComponent />}
    </SuggestionsWrapper>
  )
}

export default AutoComplete
