import texts from './texts.json'

function Terms() {
  return (
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: texts.terms }}
    />
  )
}

export default Terms
