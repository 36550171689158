import { useGlobal } from 'reactn'
import { API, graphqlOperation } from 'aws-amplify'
import { NavLink } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Error, Loading } from '../../components'
import { getConversations } from '../../graphql/queries'

function ConversationListEntry({ data, listingId }) {
  if (!data) return <></>

  const { conversationId, part1Street, part2Street, lastMessage = {}, lastRead = 0 } = data
  const { message, sender, timestamp } = lastMessage
  const name = part2Street ? `${part1Street} & ${part2Street}` : part1Street

  let unread = false
  if (lastRead < timestamp && sender !== listingId) {
    unread = true
  }

  return (
    <NavLink
      to={`/inbox/${conversationId}`}
      // className={(isActive) => `conversation${!isActive ? ' active' : ''}`}
      className={unread ? 'conversation unread' : 'conversation'}
      activeClassName="active"
    >
      <h2>{name}</h2>
      <div className="lastMessage">{message}</div>
    </NavLink>
  )
}

function Empty({ style, loading }) {
  console.log('>>>', style)
  return <div style={{ ...style, width: '100%', background: '#eee', height: '100vh' }}>{loading && <Loading />}</div>
}

function Conversations({ style }) {
  const [listing] = useGlobal('listing')
  const listingId = listing.id
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [items, setItems] = useGlobal('conversations')

  async function loadData() {
    try {
      const res = await API.graphql(graphqlOperation(getConversations, { listingId }))
      setLoading(false)
      const convs = res.data.getConversations.conversations
      setItems(convs)

      // open active conversation
      // if (!conversationId && convs.length) {
      //   history.push(`/inbox/${convs[0].conversationId}`)
      // }
    } catch (e) {
      setError(e)
    }
  }
  useEffect(loadData, [listingId])

  if (error) return <Error data={error} />

  return (
    <div style={style}>
      <h1>Meddelanden</h1>
      {loading && <Empty style={style} loading />}
      {items.map((c) => (
        <ConversationListEntry key={c.conversationId} data={c} listingId={listingId} />
      ))}
      {!loading && items.length === 0 && <div>Inga meddelanden än :)</div>}
    </div>
  )
}

export default Conversations
