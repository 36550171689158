/* eslint-disable no-underscore-dangle */
import { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import './EditFlat.css'
import FadeIn from 'react-fade-in'
import { useGlobal } from 'reactn'
import { useQuery } from '../../../utils'

import MediaAdmin from './MediaAdmin'
import PlacesAutocomplete from './GooglePlaces/PlacesAutocomplete'
import { LabelInput, Button, LinkButton, Checkbox } from '../../../components'
import texts from './texts.json'
import { saveListing } from '../../../graphql/mutations'
import Features from './Features'
import AutoComplete from '../../../components/autocomplete/Autocomplete'
import landlords from '../../../assets/landlords.json'

function EditFlat() {
  const { listingField } = useParams()
  const query = useQuery()
  const [step, setStep] = useState(query.get('step') * 1 || 1)
  const [listing, setListing] = useGlobal('listing')
  const [data, setData] = useState(listing[listingField] || {})
  const [stepComplete, setStepComplete] = useState(!!data.lat || false)
  const history = useHistory()

  async function save(obj) {
    try {
      const saveData = JSON.parse(JSON.stringify(listing))
      saveData[listingField] = obj || data
      console.log('save', saveData[listingField])
      const d = await API.graphql(graphqlOperation(saveListing, saveData))
      console.log('Save OK', d)
      setListing(d.data.saveListing)
      console.log('save finished', saveData[listingField])
    } catch (e) {
      console.error(e)
    }
  }

  function updateField(field, value) {
    setData({ ...data, [field]: value === '' ? null : value })
  }

  function updateMedia(media) {
    setData({ ...data, media })
  }

  function done() {
    save()
    history.push('/overview')
  }

  function input(label, field, type, disabled) {
    return (
      <LabelInput
        label={label}
        value={disabled ? '' : data[field]}
        type={type || 'text'}
        onChange={(e) => updateField(field, e.target.value)}
        disabled={disabled}
      />
    )
  }

  const title = <h1>{texts[`step-${step}-title`]}</h1>
  const description = (
    <div
      id="stepDescription"
      dangerouslySetInnerHTML={{
        __html: texts[`step-${step}-description`]
      }}
    />
  )
  return (
    <div className="content editFlat">
      <div id="editFlatHeader">
        <div style={{ marginBottom: 5 }}>
          Steg {step} av {5}
        </div>
        <LinkButton style={{ float: 'right', paddingTop: 0 }} onClick={done}>
          Avsluta och spara
        </LinkButton>
      </div>
      <hr style={{ width: '100%' }} />
      <p
        style={{
          fontSize: 10,
          color: 'pink',
          position: 'fixed',
          top: 0,
          right: 15
        }}
      />
      {step === 1 && (
        <>
          {title}
          {description}
          <FadeIn>
            <PlacesAutocomplete data={data} setData={setData} save={save} setStepComplete={setStepComplete} />
          </FadeIn>
        </>
      )}
      {step === 2 && (
        <>
          {title}
          {description}
          <FadeIn>
            {input('Antal rum:', 'roomCount', 'number')}
            {input('Bostadens yta (kvm):', 'area', 'number')}
            {input('Månadshyra (kr):', 'rent', 'number')}
          </FadeIn>
        </>
      )}
      {step === 3 && (
        <>
          {title}
          {description}
          <FadeIn>
            {input('Byggår:', 'constructionYear', 'number')}
            {input('Våning:', 'floor', 'number', data.floor === -100)}
            <div style={{ marginTop: -10, marginBottom: 15 }}>
              <Checkbox
                label="Bottenvåning"
                value={data.floor === -100}
                onChange={() => setData({ ...data, floor: data.floor === -100 ? null : -100 })}
                borderColor="#A9A9A9"
                focusBorderColor="#A9A9A9"
              />
            </div>
            <AutoComplete data={data} setData={setData} suggestions={landlords} />
          </FadeIn>
        </>
      )}
      {step === 4 && (
        <>
          {(!data.media || data.media.length === 0) && (
            <>
              {title}
              {description}
            </>
          )}
          <MediaAdmin items={data.media} save={save} updateMedia={updateMedia} />
        </>
      )}
      {step === 5 && (
        <>
          <>
            {title}
            {description}
          </>
          <Features data={data} setData={setData} save={save} />
        </>
      )}
      {step === 6 && (
        <>
          <>
            {title}
            {description}
          </>
          <textarea
            style={{ marginBottom: 20 }}
            value={data.description || ''}
            onChange={(e) => updateField('description', e.target.value)}
          />
        </>
      )}
      <div id="controls" style={{ marginTop: 50 }}>
        {step === 1 && <div />}
        {step > 1 && <LinkButton onClick={() => setStep(step - 1)}>&lt; Tillbaka</LinkButton>}
        {step < 6 && (
          <Button
            disabled={!stepComplete}
            onClick={() => {
              setStep(step + 1)
              save()
            }}
          >
            Nästa
          </Button>
        )}
        {step === 6 && <Button onClick={done}>Klart!</Button>}
      </div>
    </div>
  )
}

export default EditFlat
