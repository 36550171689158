function NewUser() {
  return (
    <div className="content">
      <h1>Snart!</h1>
      <p>
        Den här delen av Lya mot Lya öppnas upp när du har lagt till en annons
        för din lägenhet samt angett dina bytespreferenser.
      </p>
      <p>
        På <a href="/overview">profilsidan</a> kan du färdigställa din annons
        och ange dina önskemål.
      </p>
    </div>
  )
}
export default NewUser
