import { Link } from 'react-router-dom'
import { Icon } from '../../components'
import { getImageUrl } from '../../utils'

function ExitFullScreen() {
  return (
    <div id="user-controls" style={{ background: 'gray' }}>
      <Link to="/matches">
        <Icon
          type="duotone"
          style={{
            color: 'white',
            fontSize: 35,
          }}
          icon="times-circle"
        />
      </Link>
    </div>
  )
}

function getStyle(item) {
  const url = getImageUrl(item, 800)
  if (!url) return {}
  return {
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${url})`,
  }
}

function MediaMobile({ id, items }) {
  return (
    <>
      <ExitFullScreen />
      <Link to={`/flat/${id}/media`}>
        <div id="mediaWrapperMobile" style={getStyle(items[0])}>
          <div className="coverLabel bottomRight">{`${items.length} bilder`}</div>
        </div>
      </Link>
    </>
  )
}

export default MediaMobile
