/* eslint-disable operator-linebreak */
import { useGlobal } from 'reactn'
import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { getMatchDetails } from '../../graphql/queries'
import { Box, Error, Loading, Icon, Button } from '../../components'
import { getCurrentAnswer } from '../../utils'

function getScoreSummary(score) {
  if (score > 0.9) return 'Väldigt bra!'
  if (score > 0.75) return 'Bra!'
  if (score > 0.6) return 'Ganska bra'
  if (score > 0.4) return 'Inte så bra'

  return 'Dålig'
}

function getDirectMatchText(data) {
  const {
    part1Response,
    part2Response,
    directMatchScore
    // part1Score,
    // part2Score,
  } = data.matchDetails || {}

  if (part1Response === 'no') {
    return 'Du har svarat nej.'
  }

  if (part1Response === 'yes' && part2Response === 'yes') {
    return 'Grattis! Ni har båda tackat ja. Nu kan ni gå vidare med visningar!'
  }

  if (directMatchScore > 0.9) {
    return 'Möjligheten till ett lyckat direktbyte är mycket hög.'
  }
  if (directMatchScore > 0.75) {
    return 'Möjligheten till ett lyckat direktbyte är hög.'
  }
  if (directMatchScore > 0.6) {
    return 'Möjligheten till ett lyckat direktbyte är ganska hög.'
  }
  if (directMatchScore > 0.4) {
    return 'Möjligheten till ett lyckat direktbyte är inte så stor.'
  }

  return 'Möjligheten till ett lyckat direktbyte är låg.'
}

function Direct({ data }) {
  const { part2Response } = data
  let cn = 'label'
  let text = 'väntar på svar'
  if (part2Response === 'yes') {
    cn += ' success'
    text = 'har tackat ja!'
  }
  if (part2Response === 'no') {
    cn += ' fail'
    text = 'har tackat nej'
  }
  return (
    <>
      <h2>
        Direktbyte <div className={cn}>{text}</div>
      </h2>
      <div className="small">
        <p style={{ marginTop: 3, marginBottom: 3 }}>
          Matchning: {getScoreSummary(data.matchDetails.directMatchScore)}
        </p>
      </div>
      <div className="small">
        <p style={{ marginTop: 3, marginBottom: 1 }}>{getDirectMatchText(data)}</p>
      </div>
    </>
  )
}

function getTriangleAnswerString(r) {
  if (r === 'yes') {
    return '(har svarat ja)'
  }

  if (r === 'no') {
    return '(har svarat nej)'
  }

  return '(ej svarat än)'
}

function getTriangleMatchText(data, part3) {
  if (data.part1Response === 'no') {
    return 'Du har svarat nej.'
  }

  if (data.part1Response === 'yes' && part3.part2Response === 'yes' && part3.part3Response === 'yes') {
    return 'Grattis! Alla har tackat Ja. Nu kan ni gå vidare med visningar!'
  }

  if (part3.triangleScore > 0.9) {
    return 'Möjligheten till ett lyckat triangelbyte är mycket hög'
  }
  if (part3.triangleScore > 0.75) {
    return 'Möjligheten till ett lyckat triangelbyte är hög'
  }
  if (part3.triangleScore > 0.6) {
    return 'Möjligheten till ett lyckat triangelbyte är ganska hög'
  }
  if (part3.triangleScore > 0.4) {
    return 'Möjligheten till ett lyckat triangelbyte är inte så stor'
  }

  return 'Möjligheten till ett lyckat triangelbyte är låg'
}

function getTriangleIcon(response) {
  if (response === 'yes') {
    return (
      <Icon
        style={{
          fontSize: 12,
          color: 'mediumseagreen',
          marginLeft: 1,
          marginRight: 8,
          marginTop: 5
        }}
        icon="check-square"
      />
    )
  }

  if (response === 'no') {
    return (
      <>
        <Icon
          style={{
            fontSize: 12,
            color: 'brown',
            marginLeft: 1,
            marginRight: 8,
            marginTop: 5
          }}
          icon="times-square"
        />
      </>
    )
  }

  return (
    <>
      <Icon
        style={{
          fontSize: 12,
          color: 'grey',
          marginLeft: 1,
          marginRight: 8,
          marginTop: 5
        }}
        icon="question-square"
      />
    </>
  )
}

function TriangleMessage({ part3, data }) {
  const [listing] = useGlobal('listing')

  const part2flatId = data?.listing?.id || null
  const part3flatId = part3?.listing?.id || null
  const conversationId = [part2flatId, part3flatId, listing.id].sort().join('-')

  return (
    <Link
      to={{
        pathname: `/inbox/${conversationId}`
      }}
    >
      <Button
        small
        style={{
          background: 'cornflowerblue',
          marginLeft: 10
        }}
      >
        Skicka meddelande
      </Button>
    </Link>
  )
}

function Triangle({ part3, data }) {
  console.log('triangle')
  console.log('part3', part3)
  console.log('data', data)
  const part2Street = data?.listing?.flat1?.street || null
  const part3Street = part3?.listing?.flat1?.street || null
  const part2flatId = data?.listing?.id || null
  const part3flatId = part3?.listing?.id || null
  const part2Route = `/flat/${part2flatId}`
  const part3Route = `/flat/${part3flatId}`

  const { part2Response, part3Response, triangleScore } = part3

  return (
    <>
      <div className="small">Möjligt triangelbyte:</div>
      <div className="small">
        {getTriangleIcon(data.part1Response)}
        Du
        <Icon
          style={{
            fontSize: 12,
            color: 'black',
            marginLeft: 4,
            marginRight: 4,
            marginTop: 4
          }}
          icon="long-arrow-right"
        />
        <a className="small" href={part2Route}>
          {' '}
          {part2Street}
        </a>
        {` ${getTriangleAnswerString(data.part1Response)}`}
      </div>
      <div className="small">
        {getTriangleIcon(part2Response)}
        <a className="small" href={part2Route}>
          {part2Street}
        </a>
        <Icon
          style={{
            fontSize: 12,
            color: 'black',
            marginLeft: 4,
            marginRight: 4,
            marginTop: 4
          }}
          icon="long-arrow-right"
        />
        <a className="small" href={part3Route}>
          {part3Street}
        </a>
        {` ${getTriangleAnswerString(part2Response)}`}
      </div>
      <div className="small">
        {getTriangleIcon(data.part3Response)}
        <a className="small" href={part3Route}>
          {part3Street}
        </a>
        <Icon
          style={{
            fontSize: 12,
            color: 'black',
            marginLeft: 4,
            marginRight: 4,
            marginTop: 4
          }}
          icon="long-arrow-right"
        />
        Dig
        {` ${getTriangleAnswerString(part3Response)}`}
      </div>
      <div className="small">
        <p style={{ marginTop: 10, marginBottom: 3 }}>Matchning: {getScoreSummary(triangleScore)}</p>
        <p style={{ marginTop: 3, marginBottom: 0 }}>{getTriangleMatchText(data, part3)} </p>
      </div>
    </>
  )
}

function Triangles({ data }) {
  const [i, setI] = useState(0)

  const triangles = data?.matchDetails?.triangleMatches || []
  if (triangles.length === 0) return <div>Hittade inga triangelbyten</div>
  const t = triangles[i]
  const street = t?.listing?.flat1?.street || null
  if (!street) return <></>
  let prevI = i - 1
  let nextI = i + 1
  if (prevI < 0) prevI = triangles.length - 1
  if (nextI >= triangles.length) nextI = 0
  return (
    <>
      <h2>
        Triangelbyten ({i + 1} av {triangles.length})
        <TriangleMessage part3={t} data={data.matchDetails} />
      </h2>

      <Triangle part3={t} data={data.matchDetails} />
      <div
        style={{
          marginTop: 15
        }}
      >
        <Button small onClick={() => setI(prevI)} style={{ marginRight: 20 }}>
          <div>
            <Icon
              icon="chevron-left"
              type="solid"
              style={{
                marginRight: 5
              }}
            />{' '}
            Förra
          </div>
        </Button>
        <Button small onClick={() => setI(nextI)}>
          <div>
            Nästa <Icon icon="chevron-right" type="solid" style={{ marginLeft: 5 }} />
          </div>
        </Button>
      </div>
    </>
  )
}

function ChainDetails({ listing, otherListing }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [chain, setChain] = useState(null)

  const part1 = listing.id // current user listing
  const part2 = otherListing.id
  const limit = 10

  async function loadData() {
    try {
      const res = await API.graphql(graphqlOperation(getMatchDetails, { part1, part2, limit }))
      console.log('res2', res)
      setChain(res.data.getMatchDetails)
      setLoading(false)
    } catch (e) {
      setError(e)
    }
  }
  useEffect(loadData, [part1, part2, limit])

  if (loading) return <Loading message="Laddar byteskedjor..." inline />
  if (error) return <Error data={error} />
  if (!chain) return <div>Hittade inget</div>

  return (
    <>
      <Box style={{ marginTop: 20 }}>
        <Direct data={chain} />
      </Box>
      <Box style={{ marginTop: 20 }}>
        <Triangles data={chain} />
      </Box>
    </>
  )
}

function Chain({ otherListing }) {
  const [listing] = useGlobal('listing')
  const isYours = otherListing.id === listing.id

  if (isYours) {
    return (
      <Box style={{ marginTop: 20 }}>
        <h2>Möjliga byten</h2>
        <div className="small">Här syns möjliga byten för de som är intresserade av din lägenhet.</div>
      </Box>
    )
  }

  const currentAnswer = getCurrentAnswer(listing, otherListing)
  if (currentAnswer === 'no') {
    return (
      <Box style={{ marginTop: 20 }}>
        <h2>Möjliga byten</h2>
        <div className="small">Du har inga möjliga byten med den här annonsen eftersom du svarat nej.</div>
      </Box>
    )
  }
  if (currentAnswer !== 'yes' && currentAnswer !== 'no') {
    return (
      <Box style={{ marginTop: 20 }}>
        <h2>Möjliga byten</h2>
        <div className="small">När du har visat intresse syns möjliga byten med den här annonsen.</div>
      </Box>
    )
  }
  return <ChainDetails listing={listing} otherListing={otherListing} />
}

export default Chain
