/* eslint-disable react/jsx-props-no-spreading */
import { MediaItem } from '../../components'
import './FlatDesktop.css'

function MediaDesktop({ items }) {
  return (
    <div id="mediaWrapperDesktop">
      <div id="mediaWrapperDesktop-left">
        <MediaItem data={items[0]} cover width="1000" />
      </div>
      <div id="mediaWrapperDesktop-right">
        <div className="row">
          <MediaItem data={items[1]} width="300" />
          <MediaItem data={items[2]} width="300" />
        </div>
        <div className="row">
          <MediaItem data={items[3]} width="300" />
          <MediaItem data={items[4]} width="300" />
        </div>
      </div>
    </div>
  )
}

export default MediaDesktop
