/* eslint-disable react/destructuring-assignment */
import styled from 'styled-components'
import boundries from '../../assets/boundriesMin.json'

const ParentName = styled.span`
  color: gray;
  font-weight: normal
`

export function GetAreaName(id) {
  const boundry = boundries.find((x) => x.osmId === id)
  if (!boundry) return null

  return (
    <>
      {boundry.name}
      {boundry.parentName && <>, <ParentName>{boundry.parentName}</ParentName></>}
    </>
  )
}

export function GetAreaNames(ids) {
  if (!ids) return null
  return (
    <>
      {ids.map((id) => <div key={id}>{GetAreaName(id)}</div>)}
    </>
  )
}
