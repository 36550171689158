import { Helmet, HelmetProvider } from 'react-helmet-async'
import './Landing.css'
import { Link } from 'react-router-dom'
import texts from './texts.json'
import Footer from './Footer'
import BlogPreview from '../blog/BlogPreview'
import { Button } from '../../components'

function Landing() {
  return (
    <div id="landing">
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>Lya mot Lya - byt lägenhet smart och enkelt</title>
          <meta
            name="description"
            content="Sveriges smartaste tjänst för att byta lägenhet! Byt din hyresrätt gratis!"
          />
          <meta property="og:title" content="Lya mot lya" />
          <meta property="og:description" content="Byt lägenhet smart och enkelt" />
          <meta property="og:url" content="https://lyamotlya.se/" />
          <meta property="og:image" content="https://lyamotlya.se/logo/500x710.png" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="sv_SE" />
          <link rel="canonical" href="https://lyamotlya.se" />
        </Helmet>
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML:
                // eslint-disable-next-line max-len
                '{"@context": "http://schema.org/", "@type": "Service", "areaServed": {"@type": "Country", "name": "Sweden"}, "availableChannel": {"@type": "ServiceChannel", "serviceUrl": "https://lyamotlya.se"}, "brand": {"@type": "Brand", "name": "Lya mot lya", "url": "https://lyamotlya.se"}, "description":"Byt lägenhet smart och enkelt", "name": "Lya mot lya", "url": "https://lyamotlya.se"}'
            }
          ]}
        />
        <div id="hero">
          <Link to="/login">
            <Button secondary className="login">
              Logga in
            </Button>
          </Link>
          <img id="hero-img" alt="" />
          <div id="hero-text">
            <h1>{texts.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: texts.subtitle }} />
            <Link to="/signup">
              <Button className="signup">Skapa konto</Button>
            </Link>
          </div>
        </div>

        <BlogPreview />

        <div id="about">
          <div dangerouslySetInnerHTML={{ __html: texts.about }} />
        </div>
        <Footer />
      </HelmetProvider>
    </div>
  )
}

export default Landing
