import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useParams, Redirect } from 'react-router-dom'
import './Blog.css'
import texts from './texts.json'
import BlogPreview from './BlogPreview'
import Footer from '../landing/Footer'

function Blog() {
  const { blogId } = useParams()
  const data = texts[blogId]
  if (!data) return <Redirect to="/404" />
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="description" content={data.description} />
        <meta name="keywords" content={data.keywords} />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.description} />
        <meta property="og:url" content={`https://lyamotlya.se/blog/${data['url-slug']}`} />
        <meta property="og:image" content={data['image-url']} />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="sv_SE" />
        <meta property="og:site_name" content="Lya mot lya" />
        <title>{data.title}</title>
      </Helmet>
      <Helmet
        script={[
          {
            type: 'application/ld+json',
            // eslint-disable-next-line max-len
            innerHTML: `{"@context": "http://schema.org/", "@type": "BlogPosting", "name": "${data.title}", "headline": "${data.title}", "abstract": "${data.intro}", "description": "${data.description}", "keywords": "${data.keywords}", "inLanguage": "SV", "dateCreated": "${data.dateISO}", "url": "https://lyamotlya.se/blog/${data['url-slug']}", "image": "${data['image-url']}"}`,
          },
        ]}
      />
      <div id="blog">
        <div id="date" dangerouslySetInnerHTML={{ __html: data.date }} />
        <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
        <p id="intro" dangerouslySetInnerHTML={{ __html: data.intro }} />
        <img src={data['image-url']} alt={data['image-alt']} />
        <div className="img-text">{data['image-text']}</div>
        <div dangerouslySetInnerHTML={{ __html: data.blog }} />
        <BlogPreview exclude={blogId} />
      </div>
      <Footer />
    </HelmetProvider>
  )
}

export default Blog
