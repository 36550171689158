/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getListing = /* GraphQL */ `
  query GetListing($id: Int!) {
    getListing(id: $id) {
      id
      updated
      flat1 {
        lat
        lng
        street
        postCode
        city
        floor
        roomCount
        areas
        area
        rent
        constructionYear
        landlord
        description
        features
        media {
          id
          type
          x
          y
          width
          height
        }
      }
      flat2 {
        lat
        lng
        street
        postCode
        city
        floor
        roomCount
        areas
        area
        rent
        constructionYear
        landlord
        description
        features
        media {
          id
          type
          x
          y
          width
          height
        }
      }
      want1 {
        minArea
        minRoomCount
        maxRent
        desiredFeatures
        areas
      }
      want2 {
        minArea
        minRoomCount
        maxRent
        desiredFeatures
        areas
      }
      yes
      no
    }
  }
`;
export const getMatches = /* GraphQL */ `
  query GetMatches(
    $id: Int!
    $filter: Response
    $limit: Int
    $nextToken: String
  ) {
    getMatches(id: $id, filter: $filter, limit: $limit, nextToken: $nextToken) {
      matches {
        listing {
          id
          updated
          flat1 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          flat2 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          want1 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          want2 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          yes
          no
        }
        directMatchScore
        bestTriangleMatch {
          id
          updated
          flat1 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          flat2 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          want1 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          want2 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          yes
          no
        }
        bestTriangleScore
      }
      nextToken
    }
  }
`;
export const getCompleteMatches = /* GraphQL */ `
  query GetCompleteMatches($id: Int!, $limit: Int, $nextToken: String) {
    getCompleteMatches(id: $id, limit: $limit, nextToken: $nextToken) {
      completeMatches {
        listing {
          id
          updated
          flat1 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          flat2 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          want1 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          want2 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          yes
          no
        }
        directMatch
        triangleMatch
      }
      nextToken
    }
  }
`;
export const getMatchDetails = /* GraphQL */ `
  query GetMatchDetails(
    $part1: Int!
    $part2: Int!
    $limit: Int
    $nextToken: String
  ) {
    getMatchDetails(
      part1: $part1
      part2: $part2
      limit: $limit
      nextToken: $nextToken
    ) {
      matchDetails {
        listing {
          id
          updated
          flat1 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          flat2 {
            lat
            lng
            street
            postCode
            city
            floor
            roomCount
            areas
            area
            rent
            constructionYear
            landlord
            description
            features
            media {
              id
              type
              x
              y
              width
              height
            }
          }
          want1 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          want2 {
            minArea
            minRoomCount
            maxRent
            desiredFeatures
            areas
          }
          yes
          no
        }
        directMatchScore
        part1Score
        part2Score
        part1Response
        part2Response
        triangleMatches {
          listing {
            id
            updated
            flat1 {
              lat
              lng
              street
              postCode
              city
              floor
              roomCount
              areas
              area
              rent
              constructionYear
              landlord
              description
              features
              media {
                id
                type
                x
                y
                width
                height
              }
            }
            flat2 {
              lat
              lng
              street
              postCode
              city
              floor
              roomCount
              areas
              area
              rent
              constructionYear
              landlord
              description
              features
              media {
                id
                type
                x
                y
                width
                height
              }
            }
            want1 {
              minArea
              minRoomCount
              maxRent
              desiredFeatures
              areas
            }
            want2 {
              minArea
              minRoomCount
              maxRent
              desiredFeatures
              areas
            }
            yes
            no
          }
          triangleScore
          part2Score
          part3Score
          part2Response
          part3Response
        }
      }
      nextToken
    }
  }
`;
export const getConversations = /* GraphQL */ `
  query GetConversations($listingId: Int!, $limit: Int, $nextToken: String) {
    getConversations(
      listingId: $listingId
      limit: $limit
      nextToken: $nextToken
    ) {
      conversations {
        listingId
        conversationId
        creationDate
        lastMessage {
          conversationId
          messageId
          sender
          timestamp
          message
        }
        lastRead
        part1ListingId
        part2ListingId
        part1Street
        part2Street
      }
      nextToken
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($conversationId: ID!, $limit: Int, $nextToken: String) {
    getMessages(
      conversationId: $conversationId
      limit: $limit
      nextToken: $nextToken
    ) {
      messages {
        conversationId
        messageId
        sender
        timestamp
        message
      }
      nextToken
    }
  }
`;
export const getAreas = /* GraphQL */ `
  query GetAreas($lat: Float!, $lng: Float!) {
    getAreas(
      lat: $lat
      lng: $lng
    )
  }
`;
