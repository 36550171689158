import './ToggleButtonGroup.css'

function ToggleButtonGroup({ items, value, onClick, style }) {
  function getClassName(item) {
    if (item.value === value) return 'selected'
  }
  if (!items) return <></>
  return (
    <div className="wrapper" style={style || {}}>
      {items.map((i) => {
        const key = `btn${i.label}`
        return (
          <button
            type="button"
            className={getClassName(i)}
            onClick={() => onClick(i.value)}
            key={key}
          >
            {i.label}
          </button>
        )
      })}
    </div>
  )
}

export default ToggleButtonGroup
