/* eslint-disable no-alert */
import { useGlobal } from 'reactn'
import { API, graphqlOperation } from 'aws-amplify'
import { cloneDeep, getCurrentAnswer } from '../../utils'
import { Button } from '../../components'
import { saveListing } from '../../graphql/mutations'

function Answer() {
  const [currentListing] = useGlobal('currentListing')
  const [listing, setListing] = useGlobal('listing')
  const [isMatching] = useGlobal('isMatching')

  async function save(variables) {
    await API.graphql(graphqlOperation(saveListing, variables))
  }

  if (!currentListing || !currentListing.id) return <></>
  if (!listing) return <></>
  const id = currentListing.id
  const yes = cloneDeep(listing.yes || [])
  const no = cloneDeep(listing.no || [])

  const isYours = listing.id === currentListing.id
  console.log(listing.id, currentListing.id, isYours)

  const currentAnswer = getCurrentAnswer(listing, currentListing)

  if (isMatching && currentAnswer === 'no') return <></>
  function removePreviousAnswer() {
    let i = yes.indexOf(id)
    if (i > -1) yes.splice(i, 1)
    i = no.indexOf(id)
    if (i > -1) no.splice(i, 1)
  }

  async function answer(a) {
    if (isYours) return alert('Du kan inte svara på din egen annons :)')
    removePreviousAnswer()
    a.push(id)
    const editedListing = cloneDeep(listing)
    editedListing.yes = yes
    editedListing.no = no
    setListing(editedListing)
    save(editedListing)
    const answeredNo = no.indexOf(currentListing.id) > -1
    if (isMatching && answeredNo) window.location.reload()
  }

  const style = {}
  if (currentAnswer === 'yes') style.color = 'green'

  const matchControlsStyle = {}

  if (currentAnswer === 'yes') {
    matchControlsStyle.position = 'unset'
  }

  return (
    <div id="matchControls" style={matchControlsStyle}>
      {currentAnswer && (
        <div className="youAnswered" style={style}>
          Du har svarat {currentAnswer === 'yes' ? 'JA' : 'NEJ'}
        </div>
      )}

      {(!currentAnswer || currentAnswer === 'yes') && (
        <Button
          onClick={() => answer(no)}
          style={{
            background: 'dimgray',
            color: 'white',
          }}
        >
          {currentAnswer === 'yes' ? 'Ändra till nej' : 'Nej tack'}
        </Button>
      )}
      {(!currentAnswer || currentAnswer === 'no') && (
        <Button
          onClick={() => answer(yes)}
          style={{
            background: 'mediumseagreen',
            color: 'white',
          }}
        >
          {currentAnswer === 'no' ? 'Ändra till intresserad' : 'Intresserad!'}
        </Button>
      )}
    </div>
  )
}

export default Answer
