/* eslint-disable react/prop-types */
import blogs from './texts.json'

function BlogPreviewItem({ data }) {
  return (
    <div className="blog-preview-entry">
      <a href={`/blog/${data['url-slug']}`}>
        <img src={data['image-preview-url']} alt={data['image-alt']} />
        <p>
          {data.title}
        </p>
      </a>
    </div>
  )
}

function BlogPreview({ exclude }) {
  return (
    <div id="blog-preview">
      {Object.keys(blogs).map((item) => {
        const key = blogs[item]['url-slug'] || 'empty'
        if (item === exclude) return <div key={key} />
        return <BlogPreviewItem key={key} data={blogs[item]} />
      })}
    </div>
  )
}

export default BlogPreview
