import { Loader } from '@googlemaps/js-api-loader'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { API, graphqlOperation } from 'aws-amplify'
import { LabelInput } from '../../../../components'
import GeoLocationButton from './GeoLocationButton'
import { getPlaceDetails } from './utils'
import { getAreas } from '../../../../graphql/queries'

let autoComplete

const Wrapper = styled.div`
  div {
    font-weight: 700;
    margin-bottom: 5px;
  }
  margin-bottom: 15px;
`

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  version: 'weekly',
  libraries: ['places'],
  region: 'se',
  language: 'se',
  id: '__googleMapsScriptId',
})

function PlacesAutocomplete({ data, setData, save, setStepComplete }) {
  const [query, setQuery] = useState(data.street)
  const [helper, setHelper] = useState(null)
  const autoCompleteRef = useRef(null)

  useEffect(() => {
    // load google maps api
    loader
      .load()
      .then(() => {
        // eslint-disable-next-line no-use-before-define
        handleScriptLoad()
      })
      .catch(() => {
        // do something
      })
  }, [])

  const updateDataFromPlace = async (placeDetails) => {
    setQuery(placeDetails.name)
    console.log(placeDetails)
    const postCode = placeDetails.address_components.find((x) => x.types.includes('postal_code')).long_name
    const sublocality = placeDetails.address_components.find((x) => x.types.includes('sublocality')).long_name
    const city = placeDetails.address_components.find((x) => x.types.includes('postal_town')).long_name
    const location = placeDetails.geometry.location.toJSON()
    const response = await API.graphql(graphqlOperation(getAreas, location))
    const areas = response.data.getAreas
    setData({
      ...data,
      postCode,
      city: sublocality ? `${sublocality}, ${city}` : city,
      street: placeDetails.name,
      ...location,
      areas,
    })
    // await save()
    setStepComplete(true)
  }

  const handlePlaceSelect = async () => {
    setHelper('')
    setData({ ...data, postCode: null, city: null, street: null, lat: null, lng: null })
    setStepComplete(false)

    const addressObject = autoComplete.getPlace()

    // user did not select address from suggestions list
    if (!addressObject.place_id) {
      setHelper('Du måste välja en address från listan')
      // reset focus to reload suggestions list
      setTimeout(() => {
        autoCompleteRef.current.blur()
        autoCompleteRef.current.focus()
      }, 100)
      save()
      return
    }

    let placeDetails
    try {
      placeDetails = await getPlaceDetails(addressObject.place_id)
    } catch {
      setHelper('Inte en giltig adress')
      return
    }

    if (!placeDetails.types.includes('street_address')) {
      console.log('missing street', placeDetails.types, placeDetails.name)
      setHelper('Glöm inte gatunummer')
      setQuery(placeDetails.name)
      save()
      return
    }

    await updateDataFromPlace(placeDetails)
  }

  // attach autocomplete to input field
  const handleScriptLoad = () => {
    const options = {
      types: ['address'],
      componentRestrictions: { country: 'se' },
    }

    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, options)
    autoComplete.setFields(['place_id', 'name', 'types'])
    autoComplete.addListener('place_changed', () => handlePlaceSelect())
  }

  return (
    <div>
      {navigator.geolocation && <GeoLocationButton updateDataFromPlace={updateDataFromPlace} />}
      <LabelInput
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        label="Gatuadress:"
        placeholder=""
        helper={helper}
        value={query}
      />
      {data.postCode && (
        <Wrapper>
          <label>
            <div>Postnummer:</div>
            <span>{data.postCode}</span>
          </label>
        </Wrapper>
      )}

      {data.city && (
        <Wrapper>
          <label>
            <div>Postort:</div>
            <span>{data.city}</span>
          </label>
        </Wrapper>
      )}
    </div>
  )
}

export default PlacesAutocomplete
