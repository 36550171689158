import { useGlobal } from 'reactn'
import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import Flat from '../flat/Flat'
import { getMatches } from '../../graphql/queries'
import { Error, Loading } from '../../components'

function NoMatches() {
  return (
    <div className="content">
      <h1>Hoppsan!</h1>Vi hittade tyvärr ingen matchande lya.
      <p>
        <a href="/matches">Tillbaka</a>
      </p>
    </div>
  )
}

function Match() {
  const [, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [randomMatch, setRandomMatch] = useState()
  const [, setIsMatching] = useGlobal('isMatching')

  const [listing] = useGlobal('listing')
  const id = listing.id

  const variables = {
    id,
    filter: 'unanswered',
  }
  async function loadData() {
    try {
      const res = await API.graphql(graphqlOperation(getMatches, variables))
      setLoading(false)
      setData(res.data)

      let m = res.data?.getMatches?.matches || []
      m = m.filter((f) => f.listing.flat1 !== null)
      const r = m[Math.floor(m.length * Math.random())]
      setRandomMatch(r)
      setIsMatching(true)
    } catch (e) {
      setError(e)
    }
    return () => {
      console.log('>>>>>>> RESETTTINGS ')
      setIsMatching(false)
    }
  }
  useEffect(() => {
    loadData()
    return () => {
      console.log('>>>>>>> RESETTTINGS ')
      setIsMatching(false)
    }
  }, [])
  if (loading) return <Loading />
  if (error) return <Error data={error} />

  if (!randomMatch) return <NoMatches />

  return <Flat listingId={randomMatch.listing.id} />
}

export default Match
