import { Link } from 'react-router-dom'
import {
  faAngleRight,
  faSignOutAlt,
  faLifeRing,
  faQuestion,
  faFileAlt,
  faCommentEdit,
} from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Misc.css'

function MiscItem({ label, icon, route }) {
  return (
    <Link to={route}>
      <div className="menuItem">
        <FontAwesomeIcon
          className="icon"
          style={{ fontSize: 25 }}
          icon={icon}
        />
        <div>{label}</div>
        <FontAwesomeIcon
          className="icon"
          style={{ fontSize: 25 }}
          icon={faAngleRight}
        />
      </div>
    </Link>
  )
}

function Misc() {
  return (
    <div className="content" style={{ marginTop: 50 }}>
      {/* <MiscItem label="Hantera ditt konto" icon={faUser} route="misc/account" /> */}
      <MiscItem label="Hjälp" icon={faLifeRing} route="misc/help" />
      <MiscItem label="Om Lya mot Lya" icon={faQuestion} route="misc/about" />
      <MiscItem label="Villkor" icon={faFileAlt} route="misc/terms" />
      <MiscItem
        label="Lämna feedback"
        icon={faCommentEdit}
        route="misc/feedback"
      />
      <MiscItem label="Logga ut" icon={faSignOutAlt} route="signout" />
    </div>
  )
}

export default Misc
