function NotFound404() {
  return (
    <div className="route404">
      404
      <p style={{ fontSize: 12, color: 'black' }}>Sidan hittades inte</p>
    </div>
  )
}

export default NotFound404
