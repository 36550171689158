/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Image } from 'cloudinary-react'
import { Button, Icon, LinkButton, Video } from '../../../components'
import MediaUpload from './MediaUpload'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function getImgStyle() {
  const res = {
    position: 'relative',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: 5,
  }
  res.maxHeight = 200
  res.width = 'auto'
  res.maxWidth = 300

  res.animationDelay = '-0.75s'
  res.animationDuration = '0.25s'
  return res
}

function MediaItem({ data, index, isDragging, isDragDisabled, deleteItem }) {
  const style = getImgStyle(index, isDragging)

  return (
    <div
      className="mediaWrapper"
      style={{
        position: 'relative',
        marginBottom: 10,
        transform: isDragging ? 'rotate(3deg)' : 'none',
        opacity: isDragging ? 0.9 : 1,
        boxShadow: isDragging ? '3px 3px 20px rgba(0,0,0,.5)' : 'none',
        borderRadius: 5,
        animationDelay: `-${Math.random()}s`,
        animationDuration: `${Math.random() * 0.1 + 0.2}s`,
        animationName: isDragging || isDragDisabled ? 'none' : 'keyframes1',
      }}
    >
      {data.type === 'image' && <Image cloudName="lyamotlya" style={style} publicId={data.id} format="jpg" />}
      {data.type === 'video' && <Video style={style} data={data} />}

      {index === 0 && <div className="coverLabel">Omslagsbild</div>}
      {isDragDisabled && (
      <button
        type="button"
        onClick={() => deleteItem(index)}
        style={{ position: 'absolute', right: 10, top: 10, width: 'auto', border: 0 }}
      >
        <Icon className="user-controls-button" style={{ color: '#333' }} type="duotone" icon="trash" />
      </button>
      )}
    </div>
  )
}

function DraggableWrapper({ data, index, isDragDisabled, deleteItem }) {
  return (
    <Draggable isDragDisabled={isDragDisabled} disableInteractiveElementBlocking draggableId={data.id} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <div>
            <MediaItem
              isDragDisabled={isDragDisabled}
              isDragging={snapshot.isDragging}
              data={data}
              deleteItem={deleteItem}
              index={index}
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}

const MediaList = React.memo(({ items, isDragDisabled, deleteItem }) =>
  items.map((item, index) => (
    <DraggableWrapper data={item} deleteItem={deleteItem} isDragDisabled={isDragDisabled} index={index} key={item.id} />
  ))
)

function MediaAdmin({ items, updateMedia, save }) {
  const [isDragDisabled, setIsDragDisabled] = useState(true)
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (isDragDisabled && !firstUpdate.current) {
      save()
    }
    firstUpdate.current = false
  }, [items])

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const orderedItems = reorder(items, result.source.index, result.destination.index)

    updateMedia(orderedItems)
  }

  function deleteItem(index) {
    const newMedia = JSON.parse(JSON.stringify(items))
    newMedia.splice(index, 1)
    updateMedia(newMedia)
  }

  function toggleDrag() {
    if (!isDragDisabled) {
      save()
    }
    setIsDragDisabled(!isDragDisabled)
  }

  return (
    <div>
      {items && items.length > 0 && isDragDisabled && <LinkButton onClick={toggleDrag}>Ändra ordning</LinkButton>}
      {items && items.length > 0 && !isDragDisabled && (
        <div className="dragHeader">
          <p>Dra bilderna för att ändra ordningen</p>
          <Button onClick={toggleDrag}>Spara</Button>
        </div>
      )}

      <div style={{ margin: 'auto' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <div id="mediaList" ref={provided.innerRef} {...provided.droppableProps}>
                <MediaList items={items || []} deleteItem={deleteItem} isDragDisabled={isDragDisabled} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {isDragDisabled && <MediaUpload items={items || []} updateMedia={updateMedia} />}
    </div>
  )
}

export default MediaAdmin
