/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */

import { Auth } from 'aws-amplify'

export const desktopMinSize = 768
export const mobileMaxSize = 768

export const featuresTexts = {
  elevator: 'Hiss',
  balcony: 'Balkong',
  fireplace: 'Öppen spis / kakelugn',
  parking: 'Parkering finns',
  br: 'Blivande bostadsrätt',
  dishwasher: 'Diskmaskin',
  washingMachine: 'Tvättmaskin',
  bathtub: 'Badkar',
  safetyDoor: 'Säkerhetsdörr',
  storage: 'Vindsförråd / källarförråd'
}

export const featuresWantTexts = {
  ...featuresTexts,
  noBottomFloor: 'Ej bottenvåning'
}

// Test
export const amplifyTestCognito = {
  aws_appsync_graphqlEndpoint:
      'https://vyqbj3qbqjdknajxzeoujz4p3m.appsync-api.eu-north-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-north-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession()).idToken.jwtToken,
  }),
  Auth: {
    region: 'eu-north-1',
    userPoolId: 'eu-north-1_JpR5jO7v3',
    userPoolWebClientId: '4nvq7ntcr3spqbb3eevqt507ap',
  },
}

export const amplifyTestApiKey = {
  aws_appsync_graphqlEndpoint:
    'https://vyqbj3qbqjdknajxzeoujz4p3m.appsync-api.eu-north-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-north-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-rzdzaflyqbhg3gglk45hyyor4a',
  graphql_headers: undefined,
  Auth: undefined
}

// Prod
export const amplifyConfCognito = {
  aws_appsync_graphqlEndpoint:
    'https://fjghimmp5vhblgcs3plpya5bf4.appsync-api.eu-north-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-north-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession()).idToken.jwtToken,
  }),
  Auth: {
    region: 'eu-north-1',
    userPoolId: 'eu-north-1_bEIvxpoU2',
    userPoolWebClientId: '2gjckl962e55hb1hkoqcjhjdf5',
  },
}

export const amplifyConfApiKey = {
  aws_appsync_graphqlEndpoint:
    'https://fjghimmp5vhblgcs3plpya5bf4.appsync-api.eu-north-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-north-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-jlrxeuiwbvay7lywa6kqyx65by',
  graphql_headers: undefined,
  Auth: undefined
}

export const priorities = [
  {
    value: 0,
    label: 'Inte viktigt',
  },
  {
    value: 1,
    label: 'Ganska viktigt',
  },
  {
    value: 2,
    label: 'Mycket viktigt',
  },
]
