import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { useGlobal } from 'reactn'
import { Box, Button, LabelInput, LinkButton } from '../../components'

function ConfirmSignup() {
  const [error, setError] = useState()
  const [username, setUsername] = useGlobal('username')
  const [password] = useGlobal('password')
  const [code, setCode] = useState('')
  const history = useHistory()
  async function confirmSignUp() {
    setError(undefined)
    try {
      await Auth.confirmSignUp(username, code)
      console.log('Success')
      history.push('/login')
    } catch (e) {
      console.log(e)
      if (e.code === 'CodeMismatchException') setError('Fel kod, försök igen')
      setTimeout(() => setError(''), 3000)
    }
  }

  console.log('global username', username)
  console.log('global password', password)

  async function resendConfirmationCode() {
    try {
      await Auth.resendSignUp(username)
      setError('Vi har skickat en ny kod till dig.')
      setTimeout(() => setError(''), 3000)
    } catch (e) {
      console.log(e)
      setError(
        'Det gick inte att skicka en ny kod. Kontakta oss på info@lyamotlya.se så ska vi försöka lösa det.'
      )
    }
  }

  return (
    <div className="content">
      <div id="loginWrapper">
        <h1>Verifiera konto</h1>
        <p>
          Vi har mailat en kod till dig för att bekräfta din epostadress. Fyll i
          den i rutan nedan.
        </p>
        <Box>
          {error && <div className="loginError">{error}</div>}
          <LabelInput
            label="Epost"
            onEnter={confirmSignUp}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <LabelInput
            onEnter={confirmSignUp}
            label="Kod"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          <div id="controls">
            <LinkButton onClick={resendConfirmationCode}>
              Skicka koden igen
            </LinkButton>
            <Button onClick={confirmSignUp}>OK!</Button>
          </div>
        </Box>
      </div>
    </div>
  )
}

export default ConfirmSignup
