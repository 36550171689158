import { Link, useParams } from 'react-router-dom'
import { useEffect, useGlobal } from 'reactn'
import { API, graphqlOperation } from 'aws-amplify'
import { useState } from 'react'
import { getListing } from '../../graphql/queries'
import Features from './Features'
import Answer from './Answer'
import format from '../../format'
import './Flat.css'
import { Error, Icon, Loading, Desktop, Mobile, Button } from '../../components'
import Want from './Want'
import MiscFlatData from './MiscFlatData'
import MediaMobile from './MediaMobile'
import MediaDesktop from './MediaDesktop'
import Chain from './Chain'
import NextMatch from '../match/NextMatch'

function Message({ listing1 }) {
  const [listing] = useGlobal('listing')

  const isYours = listing.id === listing1.id
  if (isYours) return <></> // looking at your own

  const conversationId = [listing1.id, listing.id].sort().join('-')

  return (
    <>
      <Desktop>
        <div id="matchControls">
          <Link
            to={{
              pathname: `/inbox/${conversationId}`
            }}
          >
            <Button
              style={{
                background: 'cornflowerblue',
                color: 'white'
              }}
            >
              Skicka meddelande
            </Button>
          </Link>
        </div>
      </Desktop>
      <Mobile>
        <div style={{ display: 'flex', justifyContent: 'center', margin: 20, marginBottom: 100 }}>
          <Link
            to={{
              pathname: `/inbox/${conversationId}`
            }}
          >
            <Button
              style={{
                background: 'cornflowerblue',
                color: 'white'
              }}
            >
              Skicka meddelande
            </Button>
          </Link>
        </div>
      </Mobile>
    </>
  )
}

function Details({ listing }) {
  const { street, rent, city, area, description, features, floor, roomCount, lat, lng } = listing.flat1

  return (
    <>
      <h1 id="street">{street}</h1>
      <a
        className="location"
        href={`http://www.google.com/maps/place/${lat},${lng}`}
        target="_blank"
        rel="noreferrer"
        style={{ fontWeight: 'normal', color: 'var(--shyTextColor)' }}
      >
        <Icon className="pinIcon" type="duotone" icon="map-pin" />
        {city}
      </a>
      <div id="details">
        <div>{area !== null && format.area(area)}</div>
        <div>{roomCount !== null && format.roomCount(roomCount)}</div>
        <div>{floor !== null && format.floor(floor)}</div>
        <div style={{ display: 'block' }}>
          <div>{rent !== null && format.rent(rent)}</div>
        </div>
      </div>
      <div id="description">{description}</div>
      <Features items={features} />
      <hr />
      <MiscFlatData data={listing.flat1} />
      <hr />
      <Want listing={listing} />
    </>
  )
}

function Flat({ listingId }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [currentListing, setCurrentListing] = useGlobal('currentListing')
  let { id } = useParams()
  if (listingId) id = listingId

  async function loadData() {
    try {
      console.log('LOADING DATA')
      const res = await API.graphql(graphqlOperation(getListing, { id }))
      console.log('res', res)
      setCurrentListing(res.data.getListing)
      setLoading(false)
    } catch (e) {
      setError(e)
    }
  }
  useEffect(loadData, [id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (loading) return <Loading />
  if (error) return <Error data={error} />
  if (!currentListing) return <div id="match">Här var det tomt</div>

  const flat = currentListing.flat1
  const media = flat.media || []
  console.log('media', media)
  console.log('flat', flat)
  return (
    <>
      <Desktop>
        <div id="flatDesktopWrapper">
          <Link to={`/flat/${id}/media`}>
            <MediaDesktop items={media} />
          </Link>
          <div id="flatDesktopDetailsWrapper">
            <div style={{ width: '70%', marginRight: 70 }}>
              <Chain otherListing={currentListing} />
              <Details listing={currentListing} />
            </div>
            <div style={{ width: '30%' }}>
              <NextMatch />
              <Answer />
              <Message listing1={currentListing} />
            </div>
          </div>
        </div>
      </Desktop>
      <Mobile className="fullscreen">
        <MediaMobile id={id} items={media} />
        <div className="content">
          <Chain otherListing={currentListing} />
          <Details listing={currentListing} />
          <Message listing1={currentListing} />
        </div>
        <Answer />
        <NextMatch />
      </Mobile>
    </>
  )
}

export default Flat
