import { useState } from 'react'
import { useGlobal } from 'reactn'
import { Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'
import { Box, Button, LabelInput, LinkButton } from '../../components'

function Signup() {
  const [error, setError] = useState()
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [username, setUsername] = useGlobal('username')
  const [password, setPassword] = useGlobal('password')
  const history = useHistory()
  async function signUp() {
    setError(undefined)
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          given_name: givenName,
          family_name: familyName,
          email: username,
        },
      })

      console.log('Success')
      history.push('/confirmsignup')
    } catch (e) {
      console.log(e)
      if (e.code === 'UsernameExistsException') {
        setError('Kontot finns redan, prova att logga in')
      }
      if (e.code === 'InvalidPasswordException') {
        setError(
          'Lösenordet behöver innehålla minst en stor bokstav, en symbol och minst 8 tecken'
        )
      } else setError('Det gick inte att skapa konto')
    }
  }

  return (
    <div className="content">
      <div id="loginWrapper">
        <h1>Skapa konto</h1>
        <Box>
          {error && <div className="loginError">{error}</div>}
          <LabelInput
            label="Förnamn"
            onEnter={signUp}
            onChange={(e) => setGivenName(e.target.value)}
            value={givenName}
          />
          <LabelInput
            label="Efternamn"
            onEnter={signUp}
            onChange={(e) => setFamilyName(e.target.value)}
            value={familyName}
          />
          <LabelInput
            label="Epost"
            onEnter={signUp}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <LabelInput
            type="password"
            onEnter={signUp}
            label="Lösenord"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div id="controls">
            <Link to="/login">
              <LinkButton>Logga in</LinkButton>
            </Link>
            <Button onClick={signUp}>Skapa konto</Button>
          </div>
        </Box>
      </div>
    </div>
  )
}

export default Signup
