import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import Conversations from './Conversations'
import Conversation from './Conversation'
import { Desktop, Mobile } from '../../components'
import './Inbox.css'

function Inbox() {
  const { conversationId } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Mobile>
        {conversationId && (
          <Conversation
            conversationId={conversationId}
            className="fullscreen"
            content
          />
        )}
        {!conversationId && (
          <div className="content">
            <Conversations />
          </div>
        )}
      </Mobile>
      <Desktop>
        <div id="inboxDesktop" className="content">
          <Conversations
            style={{
              width: '30%',
              marginTop: 20,
              borderRight: '1px solid rgba(48, 48, 48, 0.25)',
            }}
            conversationId={conversationId}
          />
          <Conversation
            conversationId={conversationId}
            style={{ width: '70%', marginLeft: '0%', marginTop: 17 }}
          />
        </div>
      </Desktop>
    </>
  )
}

export default Inbox
