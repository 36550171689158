/* eslint-disable react/jsx-one-expression-per-line */
import { useState } from 'react'
import { useEffect } from 'reactn'
import Button from '../buttons'
import Icon from '../icon'

function Loading({ message, inline }) {
  const [msg, setMsg] = useState(false)
  const [fail, setFail] = useState(false)

  useEffect(() => {
    const t = setTimeout(() => setMsg(true), 1000)
    const t2 = setTimeout(() => {
      setFail(true)
      setMsg(false)
    }, 5000)
    return () => {
      clearTimeout(t)
      clearTimeout(t2)
    }
  }, [])

  let style
  if (inline) {
    style = {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      padding: 30,
    }
  } else {
    style = {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      height: '90vh',
    }
  }
  return (
    <div style={style}>
      <div>
        {!fail && (
          <Icon
            className="icon"
            type="duotone"
            style={{ fontSize: 100, color: '#E95D00' }}
            spin
            icon="circle-notch"
          />
        )}
        {!fail && (
          <div
            style={{
              color: 'gray',
              marginTop: 20,
              visibility: msg ? 'visible' : 'hidden',
            }}
          >
            {message || 'Snart så!'}
          </div>
        )}
        {fail && (
          <div
            style={{
              marginTop: 20,
            }}
          >
            <h1 style={{ marginBottom: 50 }}>Något gick fel</h1>
            <Button onClick={() => window.location.reload()}>
              Ladda om sidan
            </Button>
            <p style={{ marginTop: 50 }}>
              Eller prova att <a href="/login">logga in</a> igen
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Loading
