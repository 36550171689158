import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { Icon } from '../../components'

async function signOut() {
  try {
    await Auth.signOut()
    setTimeout(() => { document.location = './login' }, 2000)
  } catch (error) {
    console.log('error signing out: ', error)
  }
}

function Signout() {
  useEffect(signOut, [])

  return (
    <div className="content" style={{ textAlign: 'center', marginTop: 150 }}>
      <h1>Ha det fint, vi ses snart igen!</h1>
      <Icon
        className="icon"
        type="duotone"
        style={{ fontSize: 100, color: '#E95D00' }}
        icon="unicorn"
      />
    </div>
  )
}

export default Signout
