/* eslint-disable camelcase */
import { useState, useEffect } from 'react'
import { Button } from '../../../components'
import uploadWidgetSettings from './uploadWidgetSettings'

function MediaUpload({ items, updateMedia }) {
  const [media, setMedia] = useState()
  useEffect(() => {
    if (media) {
      updateMedia([...items, media])
    }
  }, [media])

  function showUploadWidget() {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: 'lyamotlya',
        uploadPreset: 'web-client',
        language: 'sv',
        cropping: false,
        multi: true,
        text: uploadWidgetSettings.texts,
        styles: uploadWidgetSettings.styles,
        sources: ['local'],
      },
      (error, result) => {
        if (error) {
          console.error(error)
        } else if (result && result.event && result.event === 'success') {
          const { public_id, resource_type } = result.info
          const item = { id: public_id, type: resource_type }
          setMedia(item)
        }
      }
    )
    widget.open()
  }

  return (
    <Button
      onClick={() => showUploadWidget()}
    >
      {!items || items.length === 0 ? 'Ladda upp bild och film' : 'Ladda upp fler'}
    </Button>
  )
}

export default MediaUpload
