const styles = {
  palette: {
    window: '#F5F5F5',
    sourceBg: '#FFFFFF',
    windowBorder: '#90a0b3',
    tabIcon: '#0094c7',
    inactiveTabIcon: '#69778A',
    menuIcons: '#0094C7',
    link: '#53ad9d',
    action: '#8F5DA5',
    inProgress: '#0194c7',
    complete: '#53ad9d',
    error: '#c43737',
    textDark: '#000000',
    textLight: '#FFFFFF',
  },
  fonts: {
    default: null,
    "'Poppins', sans-serif": {
      url: 'https://fonts.googleapis.com/css?family=Poppins',
      active: true,
    },
  },
}
const texts = {
  sv: {
    or: 'Eller',
    back: 'Tillbaka',
    advanced: 'Avancerat',
    close: 'Stäng',
    no_results: 'Inga resultat',
    search_placeholder: 'Sök',
    about_uw: 'Om',
    menu: {
      files: 'Mina filer',
      camera: 'Kamera',
    },
    selection_counter: { selected: 'Vald' },
    actions: {
      upload: 'Ladda upp',
      clear_all: 'Rensa alla',
      log_out: 'Logga ut',
    },
    notifications: {
      general_error: 'Något gick fel',
      general_prompt: 'Är du säker?',
      limit_reached: 'Du kan inte välja fler filer.',
      invalid_add_url: 'URL-adressen måste vara giltig.',
      no_new_files: 'Inga nya filer har laddats upp',
      great: 'Bra',
    },
    advanced_options: {
      public_id_ph: 'Publkt ID',
      tags_ph: 'Lägg till tagg',
      add_new: 'Lägg till en ny tagg:',
      upload_preset_placeholder: 'Upload Preset',
    },
    landscape_overlay: {
      title: 'Fungerar endast i porträttläge',
      description: 'Rotera din enhet för att fortsätta.',
    },
    queue: {
      title: 'Uppladdningskö',
      title_uploading_with_counter: 'Laddar upp {{num}} st',
      title_uploading: 'Laddar upp',
      mini_title: 'Uppladdad',
      mini_title_uploading: 'Laddar upp',
      show_completed: 'Visa klara',
      retry_failed: 'Det gick inte att försöka igen',
      abort_all: 'Avbryt alla',
      upload_more: 'Ladda upp fler',
      done: 'Klart',
      mini_upload_count: '{{num}} uppladdade',
      mini_failed: '{{num}} misslyckades',
      statuses: {
        uploading: 'Laddar upp...',
        error: 'Fel',
        uploaded: 'Klart',
        aborted: 'Avbrutet',
      },
    },
    uploader: {
      filesize: {
        na: 'Finns inte',
        b: '{{size}} Bytes',
        k: '{{size}} KB',
        m: '{{size}} MB',
        g: '{{size}} GB',
        t: '{{size}} TB',
      },
      errors: {
        file_too_large:
          'Filstorleken ({{size}}) är större än max ({{allowed}})',
        max_dimensions_validation:
          'Bildens upplösning ({{width}}X{{height}}) är större än tillåtet: ({{maxWidth}}X{{maxHeight}})',
        min_dimensions_validation:
          'Bildens upplösning ({{width}}X{{height}}) är mindre än minsta tillåtna: ({{minWidth}}X{{minHeight}})',
        unavailable: 'Finns ej',
        max_number_of_files: 'Du har nått maxgränsen för antal filer',
        allowed_formats: 'Filformatet stöds ej',
        max_file_size: 'Filen är för stor',
        min_file_size: 'Filen är för liten',
      },
    },
    crop: {
      title: 'Hantera bild',
      crop_btn: 'Beskär',
      skip_btn: 'Beskär ej',
      reset_btn: 'Rensa',
      close_btn: 'Ja',
      close_prompt:
        'Om du stänger panelen så avbryts alla uppladdningar. Vill du fortsätta?',
      image_error: 'Fel vid bilduppladdning',
      corner_tooltip: 'Dra i hörnet för att ändra storlek',
      handle_tooltip: 'Dra i handtaget för att ändra storlek',
    },
    camera: {
      main_title: 'Kamera',
      capture: 'Ta en bild',
      cancel: 'Avbryt',
      take_pic: 'Ta en bild och ladda upp den',
      explanation:
        'Se till att din kamera fungerar och att din browser har tillåtelse att använda den',
      camera_error: 'Det gick inte att komma åt din kamera',
      retry: 'Försök med kameran igen',
      file_name: 'Camera_{{time}}',
    },
    local: {
      browse: 'Välj filer',
      main_title: 'Ladda upp filer',
      dd_title_single: 'Drag och släpp bild eller film här',
      dd_title_multi: 'Drag och släpp bilder eller filmer här',
      drop_title_single: 'Dra hit en fil för att ladda upp',
      drop_title_multiple: 'Släpp filen här för att ladda upp',
    },
  },
}

export default { texts, styles }
