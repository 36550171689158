import { Icon } from '../../components'

import { featuresTexts } from '../../settings'

const icons = {
  parking: 'car',
  elevator: 'sort-circle-up',
  balcony: 'sun-cloud',
  fireplace: 'fireplace',
  washingMachine: 'washer',
  dishwasher: 'window-alt',
  bathtub: 'bath',
  safetyDoor: 'door-closed',
  br: 'file-signature',
  storage: 'warehouse',
}

function Features({ items }) {
  if (!items) return <></>

  // const test = [
  //   'elevator',
  //   'balcony',
  //   'fireplace',
  //   'parking',
  //   'br',
  //   'dishwasher',
  //   'washingMachine',
  //   'bathtub',
  //   'safetyDoor',
  //   'storage',
  // ]

  return (
    <div id="features">
      <h2>Bekvämligheter</h2>
      {items.map((i) => (
        <div key={i} className="feature">
          <div>{featuresTexts[i]}</div>
          <div style={{ width: 30, textAlign: 'center' }}>
            <Icon type="light" style={{ fontSize: 25 }} icon={icons[i]} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Features
