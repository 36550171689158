/* eslint-disable no-use-before-define */
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { useEffect } from 'react'
import { useGlobal } from 'reactn'
import { useMediaQuery } from 'react-responsive'
import {
  // ConfirmSignIn,
  // ConfirmSignUp,
  // ForgotPassword,
  // RequireNewPassword,
  // SignUp,
  // VerifyContact,
  SignIn,
  withAuthenticator,
} from 'aws-amplify-react'
import Menu from './components/menu/Menu'
import { desktopMinSize } from './settings'
import { Login } from './routes'
import { Loading, Logo, Mobile, Desktop } from './components'
import { saveListing } from './graphql/mutations'
import { getListing } from './graphql/queries'

function HeaderDesktop() {
  return (
    <div id="header">
      <div id="headerContent">
        <Logo />
        <Menu />
      </div>
    </div>
  )
}

function LoggedIn({ children, showMenu }) {
  const [user, setUser] = useGlobal('user')
  const [listing, setListing] = useGlobal('listing')
  const [, setIsDesktop] = useGlobal('isDesktop')
  const [, setIsMobile] = useGlobal('isMobile')

  const handleMediaQueryChange = (v) => {
    setIsDesktop(v)
    setIsMobile(!v)
  }
  const isDesktopScreen = useMediaQuery({ minDeviceWidth: desktopMinSize }, undefined, handleMediaQueryChange)

  function getListingId(u) {
    try {
      const listingIds = JSON.parse(u.attributes['custom:listingIds'])
      const listingId = listingIds[0]
      return listingId
    } catch (e) {
      return null
    }
  }

  async function loadListing(u) {
    const id = getListingId(u)
    try {
      const res = await API.graphql(graphqlOperation(getListing, { id }))
      console.log('Listing loaded', res.data.getListing)
      let l = res.data.getListing
      if (!l) {
        // user has no listing, make a first save to create
        l = { id }
        console.log('No listing, saving')
        const res2 = await API.graphql(graphqlOperation(saveListing, { id }))
        console.log('save result:', res2)
      }
      setListing(l)
    } catch (err) {
      console.log('error fetching listing', err)
    }
  }

  async function init() {
    setIsDesktop(isDesktopScreen)
    setIsMobile(!isDesktopScreen)

    try {
      let u = user
      if (!u) {
        // init user
        u = await Auth.currentAuthenticatedUser()
        setUser(u)
      }
      if (!listing) {
        // init listing
        await loadListing(u)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(init, [])

  if (!user) return <Loading message="Loggar in" />
  if (!listing) return <Loading message="Laddar dina uppgifter" />

  return (
    <>
      <Mobile>
        <div id="loggedInWrapper">{children}</div>
        {showMenu && <Menu />}
      </Mobile>
      <Desktop>
        <HeaderDesktop />
        <div className="content">{children}</div>
      </Desktop>
    </>
  )
}

class MySignIn extends SignIn {
  render() {
    return <Login />
  }
}

export default withAuthenticator(LoggedIn, false, [
  <MySignIn />,
  // <ConfirmSignIn/>,
  // <VerifyContact/>,
  // <SignUp/>,
  // <ConfirmSignUp/>,
  // <ForgotPassword/>,
  // <RequireNewPassword />
])
