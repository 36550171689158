/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import styled from 'styled-components'

const Button = styled.button`
  background: #d96629;
  padding: 15px 30px;
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 7px;
  box-sizing: border-box;
  font-size: 0.8rem;
  width: auto;
  cursor: pointer;
  &:disabled {
    background: #aaa;
  }
  ${(props) =>
    props.small &&
    `
      padding: 6px 12px;
      font-size: 0.7rem;
    `}
  ${(props) =>
    props.secondary &&
    `
        background: #e9e9e9;
        color: black;
        border: 1px solid black
    `}
`

export default Button
