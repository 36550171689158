import { useGlobal } from 'reactn'
import './Menu.css'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Icon } from '..'
import useInterval from './userInterval'
import { getConversations } from '../../graphql/queries'

function MenuItem({ label, route, icon, requiresFlat, unreadCount }) {
  const [listing] = useGlobal('listing')
  const { flat1, want1 } = listing

  let r = route
  const style = { fontSize: 25 }
  let replacedIcon
  if (requiresFlat && (!flat1 || !want1)) {
    r = 'newuser'
    style.color = '#ccc'
    replacedIcon = 'lock'
  }

  return (
    <Link to={`/${r}`}>
      <div className="item">
        <Icon className="icon" type="light" style={style} icon={replacedIcon || icon} />
        <div className="text">
          {label}
          {unreadCount ? <span style={{ color: '#d3658d' }}> ({unreadCount})</span> : ''}
        </div>
      </div>
    </Link>
  )
}

function InboxItem({ label, route, icon, requiresFlat }) {
  const [listing] = useGlobal('listing')
  const [unreadCount, setUnreadCount] = useState(0)
  const [conversations] = useGlobal('conversations')

  const update = async () => {
    const listingId = listing.id
    try {
      const res = await API.graphql(graphqlOperation(getConversations, { listingId }))
      const convs = res.data.getConversations.conversations
      const unread = convs.filter(
        ({ lastRead = 0, lastMessage }) => lastMessage?.sender !== listingId && lastRead < (lastMessage?.timestamp || 0)
      )
      setUnreadCount(unread.length)
    } catch (e) {
      return []
    }
  }

  useInterval(() => {
    update()
  }, 2 * 60 * 1000)

  useEffect(() => {
    update()
  }, [conversations])

  return <MenuItem label={label} route={route} icon={icon} requiresFlat={requiresFlat} unreadCount={unreadCount} />
}

function Menu() {
  return (
    <div id="menu">
      <MenuItem label="Profil" route="overview" icon="user" />
      <MenuItem label="Byten" route="matches" icon="list-ul" requiresFlat />
      <MenuItem label="Matcha" route="match" icon="exchange" requiresFlat />
      <InboxItem label="Inkorg" route="inbox" icon="envelope" requiresFlat />
      <MenuItem label="Övrigt" route="misc" icon="ellipsis-h-alt" />
    </div>
  )
}

export default Menu
