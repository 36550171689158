import '../flat/editflat/EditFlat.css'
import './Want.css'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import boundries from '../../assets/boundriesMin.json'
import { GetAreaName } from '../../components/areaName/AreaName'
import { Icon } from '../../components'

const BadgeWrapper = styled.div`
  margin-bottom: 10px;
`

const AreaBadge = styled.div`
  background: beige;
  padding: 7px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  svg {
    margin-right: 5px;
    cursor: pointer;
  }
`

const AreaLabel = styled.span``

const getDisplayName = (boundry) => (boundry.parentName ? `${boundry.name}, ${boundry.parentName}` : boundry.name)

function Badge({ id, onClick }) {
  return (
    <AreaBadge>
      <AreaLabel>{GetAreaName(id)}</AreaLabel>
      <Icon icon="times" type="light" onClick={() => onClick(id)} />
    </AreaBadge>
  )
}

const commonOptions = boundries
  .filter((boundry) => {
    const name = getDisplayName(boundry).toLocaleLowerCase()
    return name.includes('stockholm') || name.includes('malmö') || name.includes('göteborg')
  })

const toOption = (boundry) => ({
  value: boundry.osmId,
  label: getDisplayName(boundry),
})

const customStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    display: 'none',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
}

function Areas({ data, setData }) {
  const handleInputChange = (newValue) => {
    console.log({ newValue })
    const areas = [...(data.areas || []), newValue.value]
    setData({ ...data, areas })
  }

  const loadOptions = (value) => {
    const filtered = boundries.filter(
      (x) => x.name.toLocaleLowerCase().includes(value) || x.parentName?.toLocaleLowerCase().includes(value)
    ).filter((x) => !data.areas?.includes(x.osmId))
    return Promise.resolve(filtered.map(toOption))
  }

  const onRemove = (id) => {
    console.log(id)
    const areas = data.areas.filter((x) => x !== id)
    setData({ ...data, areas })
  }
  return (
    <>
      <BadgeWrapper>
        {data.areas?.map((id) => (
          <Badge id={id} onClick={onRemove} key={id} />
        ))}
      </BadgeWrapper>
      <AsyncSelect
        cacheOptions
        defaultOptions={commonOptions.filter((x) => !data.areas?.includes(x.osmId)).map(toOption)}
        placeholder="Ange ett område..."
        onChange={handleInputChange}
        value=""
        formatOptionLabel={(options) => {
          const label = options.label.split(',').map((name, i) => {
            if (i > 0) return ` <span style="color:gray">${name.trim()}</span>`
            return name
          })
          return <span dangerouslySetInnerHTML={{ __html: label.join(',') }} />
        }}
        isSearchable
        styles={customStyles}
        loadOptions={loadOptions}
        noOptionsMessage={() => 'Inga förslag'}
      />
    </>
  )
}

export default Areas
