function Error({ data }) {
  console.log('Error', data)
  return (
    <div className="content" style={{ marginTop: 50 }}>
      <h1>Något gick fel</h1>
      <p>
        Det uppstod ett oväntat fel. Kontakta oss gärna på{' '}
        <b>info@lyamotlya.se</b> och berätta vad som hände.
      </p>
    </div>
  )
}

export default Error
