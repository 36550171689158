/* eslint-disable operator-linebreak */
import { Link } from 'react-router-dom'
import { useGlobal } from 'reactn'
import { API, graphqlOperation } from 'aws-amplify'
import { Button, Icon } from '../../components'
import { saveListing } from '../../graphql/mutations'
import './Overview.css'
import { GetAreaName } from '../../components/areaName/AreaName'

function hasKey(obj, needed) {
  if (!obj) return false

  return needed.every((field) => {
    if (!obj[field]) {
      return false
    }
    return true
  })
}

function isFlatComplete(flat) {
  const needed = ['street', 'roomCount', 'rent', 'area', 'areas']
  if (!hasKey(flat, needed)) {
    return false
  }
  return flat.floor !== null
}

function isWantComplete(want) {
  const needed = ['minRoomCount', 'maxRent', 'minArea', 'areas']
  return hasKey(want, needed)
}

function FlatNewUser({ field }) {
  return (
    <div>
      <p>Välkommen till Lya mot Lya! Det första att göra är att lägga till ditt eget bytesobjekt.</p>

      <Link to={`/flat/edit/${field}`}>
        <Button style={{ marginTop: 20 }}>Lägg till lägenhet</Button>
      </Link>
    </div>
  )
}

function FlatDetails({ field }) {
  const [listing] = useGlobal('listing')
  const data = listing[field] || {}
  const { street } = data
  const streetName = street || 'Din lägenhet'

  const objects = [
    { field: 'street', text: 'Gatuadress', step: 1 },
    { field: 'roomCount', text: 'Antal rum', step: 2 },
    { field: 'area', text: 'Storlek', step: 2 },
    { field: 'rent', text: 'Hyra', step: 2 },
    { field: 'floor', text: 'Våning', step: 3 },
  ]

  const complete = {
    street: data.street !== null,
    floor: data.floor !== null,
    rent: data.rent !== null,
    roomCount: data.roomCount !== null,
    area: data.area !== null,
  }

  const flatIsComplete = isFlatComplete(data)
  console.log({ flat: data })
  return (
    <div>
      <h2>
        {streetName}
        <Link style={{ marginLeft: 20 }} to={`/flat/${listing.id}`}>
          (visa annons)
        </Link>
      </h2>
      {flatIsComplete && (
        <p>
          {' '}
          <Icon
            style={{
              fontSize: 20,
              color: 'green',
              marginRight: 10,
            }}
            icon="check-square"
          />
          Din annons är godkänd och publicerad!
        </p>
      )}
      {!flatIsComplete && (
        <div id="features">
          {objects.map((o) => (
            <div key={o.field} className="feature" style={{ marginBottom: 10 }}>
              <Link to={`/flat/edit/${field}?step=${o.step}`}>
                <div>{o.text}</div>
              </Link>
              <div style={{ width: 30, textAlign: 'center' }}>
                <Icon
                  style={{
                    fontSize: 20,
                    color: complete[o.field] ? 'green' : '#eee',
                  }}
                  icon="check-square"
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <Link to={`/flat/edit/${field}`}>Ändra detailer</Link>
    </div>
  )
}

function Flat({ field }) {
  const [listing] = useGlobal('listing')
  const data = listing[field]
  return (
    <div>
      {!data && <FlatNewUser field={field} />}
      {data && <FlatDetails field={field} />}
    </div>
  )
}

function Want({ field }) {
  const [listing] = useGlobal('listing')
  const data = (listing[field] && listing[field][0])
  const to = `/want/${field}`

  console.log('want', data)

  const objects = [
    { field: 'minRoomCount', text: 'Antal rum', step: 2, unit: 'st' },
    { field: 'maxRent', text: 'Hyra', step: 2, unit: 'kr' },
    { field: 'minArea', text: 'Storlek', step: 2, unit: 'kvm' },
  ]

  const wantIsComplete = isWantComplete(data)

  return (
    <>
      <hr style={{ marginTop: 30 }} />
      <div style={{ marginTop: 50 }}>
        <h2>Dina önskemål</h2>
        <div style={{ marginTop: 20 }}>
          {data && (
            <table style={{ marginBottom: 20 }}>
              <tbody>
                {objects.map((o) => (
                  <tr key={o.field}>
                    <td className="wantItem" style={{ marginBottom: 10 }}>
                      {o.text}:
                    </td>
                    <td style={{ paddingLeft: 20, fontWeight: 700 }}>
                      {data[o.field] && `${data[o.field]} ${o.unit}`}
                      {!data[o.field] && <span style={{ fontWeight: 400, color: 'darkgray' }}>saknas</span>}
                    </td>
                  </tr>
                ))}
                <tr key="areas">
                  <td className="wantItem" style={{ marginBottom: 10, verticalAlign: 'top' }}>
                    Område
                  </td>
                  <td style={{ paddingLeft: 20, fontWeight: 700 }}>
                    { data.areas && data.areas.map((id) => (<div key={id}>{GetAreaName(id)}</div>))}
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          {wantIsComplete && (
            <p>
              {' '}
              <Icon
                style={{
                  fontSize: 20,
                  color: 'green',
                  marginRight: 10,
                }}
                icon="check-square"
              />
              Din annons önskemål är ifyllda!
            </p>
          )}
          {data && <Link to={to}>Ändra önskemål</Link>}
          {!data && (
            <Link style={{ marginTop: 10 }} to={to}>
              <Button>Lägg till sökprofil</Button>
            </Link>
          )}
        </div>
      </div>
    </>
  )
}

function Reset() {
  const [listing, setListing] = useGlobal('listing')
  async function reset() {
    // eslint-disable-next-line no-alert
    const r = window.confirm('Din data kommer rensas. Fortsätt?')
    if (!r) return
    console.log('resetting')
    const res = await API.graphql(
      graphqlOperation(saveListing, {
        id: listing.id,
      })
    )
    setListing(res.data.saveListing)
  }
  return (
    <Button style={{ marginTop: 250, background: 'darkred' }} onClick={reset}>
      Återställ (för test)
    </Button>
  )
}

function Overview() {
  const [user] = useGlobal('user')
  const [listing] = useGlobal('listing')
  console.log('listing', listing)

  const flatIsComplete = isFlatComplete(listing.flat1)

  return (
    <div className="content">
      <h1>Hej {user.attributes.given_name}!</h1>

      <Flat field="flat1" />

      {flatIsComplete && <Want field="want1" />}

      <Reset />
    </div>
  )
}

export default Overview
