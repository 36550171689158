/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addInterestedUser = /* GraphQL */ `
  mutation AddInterestedUser($email: AWSEmail!, $comment: String) {
    addInterestedUser(email: $email, comment: $comment) {
      id
      email
      timestamp
      comment
    }
  }
`;
export const saveListing = /* GraphQL */ `
  mutation SaveListing(
    $id: Int
    $flat1: FlatInput
    $flat2: FlatInput
    $want1: [WantInput]
    $want2: [WantInput]
    $yes: [Int!]
    $no: [Int!]
  ) {
    saveListing(
      id: $id
      flat1: $flat1
      flat2: $flat2
      want1: $want1
      want2: $want2
      yes: $yes
      no: $no
    ) {
      id
      updated
      flat1 {
        lat
        lng
        street
        postCode
        city
        floor
        roomCount
        area
        areas
        rent
        constructionYear
        landlord
        description
        features
        media {
          id
          type
          x
          y
          width
          height
        }
      }
      flat2 {
        lat
        lng
        street
        postCode
        city
        floor
        roomCount
        area
        areas
        rent
        constructionYear
        landlord
        description
        features
        media {
          id
          type
          x
          y
          width
          height
        }
      }
      want1 {
        minArea
        minRoomCount
        maxRent
        desiredFeatures
        areas
      }
      want2 {
        minArea
        minRoomCount
        maxRent
        desiredFeatures
        areas
      }
      yes
      no
    }
  }
`;
export const getConversation = /* GraphQL */ `
  mutation GetConversation($listingId: Int!, $conversationId: String!) {
    getConversation(listingId: $listingId, conversationId: $conversationId) {
      listingId
      conversationId
      creationDate
      lastMessage {
        conversationId
        messageId
        sender
        timestamp
        message
      }
      lastRead
      part1ListingId
      part2ListingId
      part1Street
      part2Street
    }
  }
`;
export const sendReadConfirmation = /* GraphQL */ `
  mutation SendReadConfirmation($listingId: Int!, $conversationId: ID!) {
    sendReadConfirmation(
      listingId: $listingId
      conversationId: $conversationId
    ) {
      listingId
      conversationId
      creationDate
      lastMessage {
        conversationId
        messageId
        sender
        timestamp
        message
      }
      lastRead
      part1ListingId
      part2ListingId
      part1Street
      part2Street
    }
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage($conversationId: ID!, $sender: Int!, $message: String!) {
    sendMessage(
      conversationId: $conversationId
      sender: $sender
      message: $message
    ) {
      conversationId
      messageId
      sender
      timestamp
      message
    }
  }
`;
