/* eslint-disable operator-linebreak */
import { useState } from 'react'
import { useGlobal } from 'reactn'
import { Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'

import { Box, Button, LabelInput, LinkButton } from '../../components'

function Login() {
  const [error, setError] = useState()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [, setUser] = useGlobal('user')
  const history = useHistory()

  async function signIn() {
    setError(undefined)
    try {
      const u = await Auth.signIn(username, password)
      console.log('Signed in with cognito')
      setUser(u)
      console.log('Login success', u)
      history.push('/overview')
    } catch (e) {
      setError(true)
      console.log('error signing in', e)
    }
  }

  return (
    <div className="content">
      <div id="loginWrapper">
        <h1>Logga in</h1>
        <Box>
          {error && (
            <div className="loginError">Fel lösenord eller användarnamn</div>
          )}

          <LabelInput
            label="Epost"
            onChange={(e) => {
              setUsername(e.target.value)
            }}
            onEnter={signIn}
            value={username}
          />
          <LabelInput
            type="password"
            label="Lösenord"
            onEnter={signIn}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <div id="controls">
            <Link to="/signup">
              <LinkButton>Skapa konto</LinkButton>
            </Link>
            <Button onClick={signIn}>Logga in</Button>
          </div>
        </Box>
      </div>
    </div>
  )
}

export default Login
