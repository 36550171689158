import { useGlobal } from 'reactn'
import { Link } from 'react-router-dom'
import format from '../../format'
import { getCoverImage } from '../../utils'
import './Matches.css'
import { Mobile, Desktop, Icon, MediaItem } from '../../components'

function Media({ items }) {
  const cover = getCoverImage(items)
  return <MediaItem data={cover} className="matchListMediaItem" />
}

function Details({ data, style }) {
  const { street, area, city, rent, roomCount, floor } = data
  return (
    <div className="info" style={style}>
      <h3>{street}</h3>
      <div className="location">
        <Icon className="pinIcon" type="duotone" icon="map-pin" />
        {city}
      </div>
      <div className="details">
        <div>{format.area(area)}</div>
        <div>{format.roomCount(roomCount)}</div>
        <div>{format.floor(floor)}</div>
        <div>{format.rent(rent)}</div>
      </div>
    </div>
  )
}

function MatchListItem({ data }) {
  const [, setCurrentListing] = useGlobal('currentListing')
  if (!data || !data.listing || !data.listing.flat1) return <></>
  const {
    // directMatchScore,
    // bestTriangleScore,
    // bestTriangleMatch,
    listing,
  } = data

  const flat = listing.flat1
  const media = flat.media
  return (
    <Link
      to={`/flat/${listing.id}`}
      onClick={() => setCurrentListing(listing)}
      className="matchListItem"
    >
      <Mobile>
        <Media items={media} />
        <Details data={flat} />
      </Mobile>
      <Desktop>
        <Media items={media} />
        <Details data={flat} style={{ marginLeft: 20, marginTop: 10 }} />
      </Desktop>
    </Link>
  )
}

export default MatchListItem
