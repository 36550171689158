import { useMediaQuery } from 'react-responsive'
import { desktopMinSize } from '../../settings'

const Desktop = ({ children, style }) => {
  const isDesktop = useMediaQuery({ minWidth: desktopMinSize })
  if (!isDesktop) return null

  if (style) return <div style={style}>{children}</div>
  return children
}

export default Desktop
