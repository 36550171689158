/* eslint-disable jsx-a11y/media-has-caption */
function Video({ data, style }) {
  const url = `https://res.cloudinary.com/lyamotlya/video/upload/v1/${data.id}.`
  return (
    <video style={style} controls poster={`${url}jpg`}>
      <source src={`${url}webm`} type="video/webm" />
      <source src={`${url}mp4`} type="video/mp4" />
      <source src={`${url}ogv`} type="video/ogg" />
    </video>
  )
}

export default Video
